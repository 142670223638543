//import LayerPopupPortal from '@/pcomponents/common/popup/layer/layer_popup_portal';
/*
{isPopupOpen && 
  <LayerPopupPortal closePopup={()=>{setIsPopupOpen(false);}} title="팝업" >
    <WritePopupContent userName="ㅋㅋㅋㅋㅋ" closePopup={()=>{setIsPopupOpen(false);}}  ></WritePopupContent>
  </LayerPopupPortal>
}
*/
import { createPortal } from "react-dom";
import { useEffect, useRef,useState } from 'react';
import setPropsFunc from "./func/set_props/set_props";
import TitleBarArea from "./area/title_bar";

function LayerPopupPortal(props:any) {
  let myProps={
    closePopup:()=>{},
    callBack:()=>{},
    title:'',
    width:'300px',
    height:'300px',
    marginTop:"0px",
    x:"",
    y:"",
    is_win_max_width:true,
    is_win_max_height:true,
    title_bar_style:{},
    content_div_style:{},
    is_border_radius:true,
    has_header:true,
    ...props
  };
  let pop_data=setPropsFunc.geDataByProps(myProps);
  const popupBackDiv=useRef<HTMLInputElement>(null);
  const [offset, setOffset] = useState({ x: pop_data.x, y: pop_data.y });

  if(myProps.is_border_radius==false){
    myProps.title_bar_style={
      ...myProps.title_bar_style,
      "border-radius":"0",
    };
    myProps.content_div_style={
      ...myProps.content_div_style,
      "border-radius":"0",
    };
  }
  
  useEffect(() => {
    popupBackDiv.current?.addEventListener("click", (e:any)=>{
      if(e.target.classList.contains('popup-background')){
        //myProps.closePopup();
      }
    }, false);
  },[]);
  
  let popup_wrap_style={
    left:offset.x+"px",
    top:offset.y+"px",
    width:pop_data.width+"px",
    height:pop_data.height+"px",
  };

  const modalRoot = document.getElementById("modal") as HTMLElement;

  return createPortal(
    <div className="popup-background" ref={popupBackDiv} >
      <div className="popup-transparent-back"></div>
      <div className="popup-wrap" style={popup_wrap_style}>
        <div className="popup-container">
          {myProps.has_header&&
            <TitleBarArea
              title={myProps.title}
              x={pop_data.x}
              y={pop_data.y}
              setOffset={setOffset}
              title_bar_style={myProps.title_bar_style}
              closePopup={myProps.closePopup}
            ></TitleBarArea>
          }
          <div className="popup-content-div" style={myProps.content_div_style}>
            {myProps.children}
          </div>
        </div>
      </div>
    </div>
  ,modalRoot);
}

export default LayerPopupPortal;
