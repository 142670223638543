import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { add_memo_select_arr } from "@/pages/comp/basic/student/list/area/write/data/default_data";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import noimg_img from "@/img/ico/noimg.png";

function CounselInfoTopArea(props: any) {
  let myProps = {
    info: {},
    add_memo_info: {},
    set_add_memo_info: (inData: any) => { },
    is_update: false,
    is_view_mode: false,
    refresh_data: () => { },
    Style: {},
    ...props
  };
  let info = myProps.info;
  let add_memo_info = myProps.add_memo_info;
  let is_view_mode = myProps.is_view_mode;
  let user = useSelector((state: any) => state.user);

  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);

  useEffect(() => {
    
  }, []);

  const handleInputChange2 = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_add_memo_info({
      ...add_memo_info,
      ...{ [name]: value }
    });
  };

  const goCounselfileWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    let default_file_row_data = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": info.a_seq,
      "a_sort1": "counsel",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    if (!confirm("상담 사진을 저장 하시겠습니까?")) {
      return false;
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify([file_row_data]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goCounselFileDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("상담 사진을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        //파일안보이게
        myProps.refresh_data();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  const get_counsel_file_tags = () => {
    if (strFunc.is_empty(info.counsel_file_arr)) {
      return "";
    }
    let counsel_file_tags = info.counsel_file_arr.map((item: any, idx: number) => {
      let thum_img_src = noimg_img;
      if (item["is_image"] == "1") {
        thum_img_src = item["thum_img_url"];
      }
      return (
        <div key={idx} style={{ textAlign: "center" }}>
          <a href={item.img_url} target="_blank" >
            <img src={thum_img_src} style={{ width: "85%" }} />
          </a>
          {is_view_mode == false &&
            <div>
              {item.a_create_date}
              <button className="btn-s btn-red ml-1" onClick={() => {
                goCounselFileDelete(item);
              }}>X</button>
            </div>
          }
        </div>
      );
    });
    return counsel_file_tags;
  };

  return (
    <div>
      <div className="write_table_small mt-2">
        <table>
          <colgroup>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            <col width={"25%"}></col>
            <col width={"5%"}></col>
            <col width={"25%"}></col>
            <col width={"35%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={2}>Wish</th>
              <th>학생</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_wish_student}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_wish_student"}
                    value={add_memo_info.a_wish_student}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_wish_student",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
              <td rowSpan={13}>
                <div className="text-center">RUTA 테스트</div>
                <div className="text-center" >
                  {get_counsel_file_tags()}
                  <div className="mt-2">
                    {(myProps.is_update && is_view_mode == false) &&
                      <>
                        <label className="btn btn-sky" htmlFor="files_counsel" style={{ cursor: "pointer" }}>
                          사진추가
                        </label>
                        <form method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
                          <input type="file" id="files_counsel" className="input_file" name="input_file[]"
                            ref={fileInput} hidden accept="image/*" onChange={(e: any) => {
                              if (e.target.files.length > 0) {
                                goCounselfileWrite({});
                              }
                            }} />
                        </form>
                      </>
                    }
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>학부모</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_wish_parent}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_wish_parent"}
                    value={add_memo_info.a_wish_parent}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_wish_parent",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th rowSpan={5}>영어</th>
              <th style={{fontSize:"11px"}}>학습정서</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_attitude}
                  value={add_memo_info.a_en_attitude}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_en_attitude", value: inData });
                    }
                  }
                  }
                ></SelectTab>
              </td>
              <th style={{fontSize:"11px"}}>학습역량</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_ability}
                  value={add_memo_info.a_en_ability}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_en_ability", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>issue</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_issue}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_issue"}
                    value={add_memo_info.a_en_issue}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_issue",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>plan</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_plan}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_plan"}
                    value={add_memo_info.a_en_plan}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_plan",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>action</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_action}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_action"}
                    value={add_memo_info.a_en_action}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_action",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>진도</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_progress_main}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_progress_main"}
                    value={add_memo_info.a_en_progress_main}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_progress_main",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th rowSpan={5}>수학</th>
              <th style={{fontSize:"11px"}}>학습정서</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_attitude}
                  value={add_memo_info.a_math_attitude}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_math_attitude", value: inData });
                    }
                  }
                  }
                ></SelectTab>
              </td>
              <th style={{fontSize:"11px"}}>학습역량</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_ability}
                  value={add_memo_info.a_math_ability}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_math_ability", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>issue</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_issue}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_issue"}
                    value={add_memo_info.a_math_issue}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_issue",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>plan</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_plan}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_plan"}
                    value={add_memo_info.a_math_plan}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_plan",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>action</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_action}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_action"}
                    value={add_memo_info.a_math_action}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_action",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>진도</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_progress_main}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_progress_main"}
                    value={add_memo_info.a_math_progress_main}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_progress_main",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CounselInfoTopArea;