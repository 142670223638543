import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { default_info,default_detail_info } from "@/pages/comp/edu/study_diary/list/area/write/data/default_data";
//import WriteBaseArea from "./write_area";
import WriteBaseArea from "@/pages/comp/edu/study_diary/list/area/write/area/write_area";

function WriteArea(props: any) {
  let myProps = {
    stu_info: {},
    Style: {},
    list: (inData: any) => { },
    isUpdate: false,
    set_is_view_write: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  const xColumnArr = getXcolumnJson();
  const [isUpdate, setIsUpdate] = useState(myProps.isUpdate);
  const [refresh, set_refresh] = useState(false);
  let user = useSelector((state: any) => state.user);

  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const init_data = {
    ...default_info,
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_info["a_seq"],
    "a_stu_name": myProps.stu_info["a_user_name"],
    "a_study_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_writer_seq": user.user_seq,
    "a_writer": user.user_name,
    "detail_arr":[
      {
        ...default_detail_info,
        a_seq:"1",
        a_order_num:"1",
      },
      {
        ...default_detail_info,
        a_seq:"2",
        a_order_num:"2",
        a_sort:"homework",
      }
    ],
  };
  const [rowData, setRowData] = useState<any>({
    ...init_data,
    file_obj: null,
  });

  useEffect(() => {
    get_writer_info_by_ajax();
  }, []);

  const get_writer_info_by_ajax=()=>{
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }
    if (props.isUpdate) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr":[user.user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            let writer_info=response.data["data"]["info_arr"][0];
            setRowData({
              ...rowData,
              a_subject_name:writer_info["a_main_subject"],
            });
          }
        } else {

        }
      });
  };

  const goWrite = (e: any) => {
    if (rowData.a_stu_seq == "") {
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };
    let file_obj = rowData.file_obj;
    delete wRowData["file_obj"];
    if (!isUpdate) {
      wRowData["a_ymd"] = strFunc.str_replace("-", "", wRowData["a_study_date"]);
    }
    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let last_info: any = response.data["data"][0];
          last_info.file_obj = file_obj;
          goWriteFileUpload(last_info);
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  const goWriteFileUpload = (row_data: any) => {
    let is_file_exist = false;
    if (row_data.file_obj && row_data.file_obj.files.length > 0) {
      for (let i = 0; i < row_data.file_obj.files.length; i++) {
        if (!strFunc.is_empty(row_data.file_obj.files[i].name)) {
          is_file_exist = true;
        }
      }
    }

    if (is_file_exist == false) {
      alert("저장 되었습니다.");
      myProps.list({});
      return false;
    }
    let row_pri_val = row_data.a_ymd + "," + row_data.a_seq;
    let file_row_arr: any = [];
    for (let i = 0; i < row_data.file_obj.files.length; i++) {
      file_row_arr.push({
        "a_ymd": row_data.a_ymd,
        "a_seq": "",
        "a_comp_seq": user.comp_seq,
        "a_par_id": "study_diary",
        "a_par_seq": row_pri_val,
        "a_sort1": "image",
        "a_sort2": "",
        "a_sort3": "",
        "a_writer": user.user_name,
        "a_create_seq": user.user_seq,
      });
    }
    var form = row_data.file_obj.parentElement;
    var form_json_data = new FormData(form);

    form_json_data.append("data_arr", JSON.stringify(file_row_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div className={Style.write_wrap} >
      <div>
        학습일지 작성
      </div>
      <WriteBaseArea
        rowData={rowData}
        isUpdate={isUpdate}
        setRowData={setRowData}
        is_find_stu={false}
        set_refresh={()=>{set_refresh(!refresh);}}
      ></WriteBaseArea>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.set_is_view_write(false); }}>닫기</button>
      </div>
    </div>
  );
};
export default WriteArea;