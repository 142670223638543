import GNBArea from "@/pcomponents/comp_stu/layout/menu/gnb";
import prj_logo_img from "@/img/logo/logo200_stu.png";
import person_ico_img from "@/img/ico/mypage100.png";
import plantalk100_img from "@/img/ico/plantalk100.png";
import { Link,useNavigate } from 'react-router-dom';
import CheckLoginComponent from '@/pcomponents/comp/check/login_check';
import checkVersion from '@/pcomponents/comp_stu/check/version/check_version';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { setUnreadTot } from '@/store/chat/chat';
import popupFunc from "@/lib/lyg/popup";

const Header=(props:any)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user=useSelector((state:any) => state.user);
  const chat = useSelector((state:any) => state.chat);
  let my_profile_img=person_ico_img;
  if(user.profile_thum_url){
    my_profile_img=user.profile_thum_url;
  }
  
  useEffect(()=>{
    checkVersion();
    
  },[]);

  useEffect(() => {
    const timerId=setInterval(getUnreadCount,5000);
    return () => clearInterval(timerId);
  },[user]);

  const getUnreadCount=()=>{
    if(user.user_seq==""){
      return false;
    }
    let unread_list_opt={
      "s_user_seq":user.user_seq,
      "is_need_count":"1",
      "is_need_info_arr":"",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/unread/list',unread_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        dispatch(setUnreadTot({
          "tot":response.data["data"]["tot"]
        }));
      }
    });
  };
  //<GNBArea></GNBArea>
  return (
    <div id="stu_header">
      <CheckLoginComponent></CheckLoginComponent>
      <div className="header_con">
        <div className="header_left_area">
          <a
            onClick={()=>{
              let win_h=(window.innerHeight || document.documentElement.clientHeight);
              popupFunc.openPopup({'url':"/comp/chat/chat_popup",'width':1200,'height':win_h,'pop_name':'chat','auto_size':false});
            }}
          >
            <img src={plantalk100_img} style={{height:35}}/>
            {chat.unreadToT!="0"&&
              <span className="text-red-300 text-xs" >({chat.unreadToT})</span>
            }
          </a>
        </div>
        <div className="center_area">
          <a onClick={()=>{navigate("/comp_stu");}}>
            <img src={user.logo_url?user.logo_url:prj_logo_img} alt="로고" />
          </a>
        </div>
        <div className="right_area">
          <a
            onClick={()=>{
              navigate("/comp_stu/setting/mypage");
            }}
          >
          <div className="header_profile_box">
            <img className="header_profile_img" src={my_profile_img} alt="프로필" />
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;