import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";
import StudyTitleListArea from "@/pages/comp/plan/study/study/common/find/area/list";
import strFunc from '@/lib/lyg/string';

function FindStudyTitleOFStuPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    stu_seq: "",
    listOpt: {},
    row_num: 0,
    key_str: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [pageData, setPageData] = useState({
    listOpt: {
      "order_id": "a_title",
      "s_subject_seq": "",
      "s_subject_sort": "",
      "s_addon_section": "1",
      "s_addon_study": "1",
      "s_addon_use_stu": "",
      "s_addon_use": "",
      "s_addon_use_s_stu_seq": "",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...myProps.listOpt
    }
  });
  const [study_title_arr, set_study_title_arr] = useState([]);
  const [select_study_title_seq_arr, set_select_study_title_seq_arr] = useState<any>([]);
  const [study_use_arr, set_study_use_arr] = useState([]);
  const [show_detail_title_seq_arr, set_show_detail_title_seq_arr] = useState<any>([]);
  const [student_info, set_student_info] = useState({});
  const [refresh, set_refresh] = useState(false);
  useEffect(() => {

    refresh_data();
    get_student_by_ajax();
  }, []);

  const refresh_data = () => {
    list({});
    get_study_use_list_by_ajax();
  };

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', pageData.listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          set_study_title_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_study_use_list_by_ajax = () => {
    let form_json_data = {
      "s_stu_seq": myProps["stu_seq"],
      "s_addon_detail": "1",
      "order_id": "a_is_main DESC, a_is_sub DESC, a_stu_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_use_arr(response.data["data"]["info_arr"]);
          let tmp_show_detail_title_seq_arr = [];
          if (response.data["data"]["info_arr"].length > 0) {
            for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
              if (response.data["data"]["info_arr"][i]["a_is_open"] == "1") {
                tmp_show_detail_title_seq_arr.push(response.data["data"]["info_arr"][i]["a_study_title_seq"]);
              }
            }
          }
          set_show_detail_title_seq_arr(tmp_show_detail_title_seq_arr);
        } else {

        }
      });
  };

  const go_write_study_use_by_ajax = (inData: any) => {
    let opt_obj = {
      is_confirm: true,
      is_refresh: true,
      study_use_arr: study_use_arr,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["study_use_arr"])) {
      if (opt_obj["is_confirm"]) {
        alert("저장할 데이터가 없습니다.");
      }
      return false;
    }
    if (opt_obj["is_confirm"]) {
      if (!confirm("저장 하시겠습니까?")) {
        return false;
      }
    }
    let form_json_data = {
      "data_arr": opt_obj["study_use_arr"],
      "is_write_detail": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (opt_obj["is_confirm"]) {
            alert("저장 되었습니다.");
          }
          if (opt_obj["is_refresh"]) {
            refresh_data();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_student_by_ajax = () => {
    let form_json_data = {
      "s_pri_arr": [myProps["stu_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_student_info(response.data["data"]["info_arr"][0]);
          }
          if (response.data["data"]["info_arr"].length == 0) {
            alert("학생 정보가 없습니다.");
            myProps.closePopup();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const on_select_click = () => {
    let selected_row: any = [];
    for (let i = 0; i < study_title_arr.length; i++) {
      let row_study_title = study_title_arr[i];
      if (strFunc.str_in_array(row_study_title["a_seq"], select_study_title_seq_arr) != -1) {
        selected_row.push(row_study_title);
      }
    }
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key_str,
    });
    myProps.closePopup();
  };

  const on_select_detail = (inData: any) => {
    let opt_obj = {
      study_title_info: null,
      study_info: null,
      study_row_num:0,
      section_info:null,
      section_row_num:0,
      study_detail_str: "",
      ...inData
    };
    myProps.callBackData({
      info_arr: [opt_obj["study_title_info"]],
      row_num: myProps.row_num,
      key: myProps.key_str,
      study_info: opt_obj["study_info"],
      study_row_num: opt_obj["study_row_num"],
      study_detail_str: opt_obj["study_detail_str"],
      section_info: opt_obj["section_info"],
      section_row_num: opt_obj["section_row_num"],
    });
    myProps.closePopup();
  };

  return (
    <div>
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
        on_select_click={on_select_click}
      ></SearchArea>
      <StudyTitleListArea
        study_title_arr={study_title_arr}
        study_use_arr={study_use_arr}
        set_study_use_arr={set_study_use_arr}
        show_detail_title_seq_arr={show_detail_title_seq_arr}
        set_show_detail_title_seq_arr={set_show_detail_title_seq_arr}
        select_study_title_seq_arr={select_study_title_seq_arr}
        set_select_study_title_seq_arr={set_select_study_title_seq_arr}
        student_info={student_info}
        select_daily_study_title={(inData: any) => { }}
        go_write_study_use_by_ajax={go_write_study_use_by_ajax}
        refresh_data={refresh_data}
        is_show_only_use={false}
        set_refresh={() => { set_refresh(!refresh); }}
        on_select_detail={on_select_detail}
      ></StudyTitleListArea>
    </div>
  );
};
export default FindStudyTitleOFStuPopup;