import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import SearchArea from "./area/search";
import ListArea from "./area/list_area";
import WriteArea from "./area/write";

function CounselTodoArea(props:any){
   let myProps={
    stu_info:{},
    is_view_mode:true,
    ...props
  };

  let pre_month_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',-2),"Y-m-01");
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "s_date_type": "a_reserve_date",
    "order_id":"a_reserve_date DESC,a_state",
    "s_reserve_start_date": pre_month_date_str,
    "s_reserve_end_date": DateFunc.get_date_format(new Date(),"Y-m-t"),
    "s_stu_seq": "",
    "s_counseler_seq": "",
    "s_writer_seq": "",
    "s_addon_user": "1",
    "s_addon_file":"1",
    ...myProps.list_opt
  });
  const [counsel_todo_arr, set_counsel_todo_arr] = useState([]);
  const [is_view_write,set_is_view_write]=useState(false);

  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let select_date_time = new Date(myProps.select_date).getTime();
  useEffect(() => {
    
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    let tmp_list_form_json = list_form_json;
    if (myProps.stu_info) {
      if(strFunc.is_empty(myProps.stu_info["a_seq"])){
        return false;
      }
      let tmp_s_stu_seq = myProps.stu_info["a_seq"];
      tmp_list_form_json["s_stu_seq"]=tmp_s_stu_seq;
    }else{
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', tmp_list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_counsel_todo_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
  <div className={Style.counsel_wrap}>
    <div>
      상담관리 ({counsel_todo_arr.length})
      <button className="btn-s btn-sky ml-1" onClick={()=>{set_is_view_write(!is_view_write)}} >신규</button>
    </div>
    <div>
      {is_view_write&&
        <WriteArea
          stu_info={myProps.stu_info}
          Style={Style}
          list={(inData:any)=>{list(inData);set_is_view_write(false);}}
          set_is_view_write={set_is_view_write}
        ></WriteArea>
      }
    </div>
    <SearchArea
      listOpt={listOpt}
      list={list}
    ></SearchArea>
    <div>
      <ListArea 
        info_arr={counsel_todo_arr}
        set_info_arr={set_counsel_todo_arr}
        Style={Style}
        list={list}
        is_view_mode={myProps.is_view_mode}
        now_date_time={now_date_time}
        select_date_time={select_date_time}
      ></ListArea>
    </div>
  </div>
  );
};
export default CounselTodoArea;