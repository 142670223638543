import { useState, useRef, useEffect,forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import WriteArea from "@/pages/comp/edu/counsel_career/list/area/write";

const CounselCareerArea = forwardRef((props: any, ref) => {
  let myProps = {
    stu_info: {},
    is_view_mode: true,
    ...props
  };
  const [stu_info,set_stu_info]=useState<any>(myProps.stu_info);
  const [info_arr, set_info_arr] = useState([]);
  const write_area_ref=useRef<any>(null);

  useEffect(() => {
    
    list();
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const setInitData=(inData:any)=>{
    let opt_obj={
      stu_info: {},
      ...inData
    };
    set_stu_info(opt_obj["stu_info"]);
    list();
  };

  const list = () => {
    if(strFunc.is_empty(stu_info["a_seq"])){
      return false;
    }
    let list_form_json: any = {
      "s_stu_seq":stu_info["a_seq"],
      "order_id":"a_counsel_date DESC, a_create_date DESC",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_career/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_info_arr(response.data["data"]["info_arr"]);
          if(write_area_ref.current){
            let row_is_update=false;
            let tmp_row_data={};
            if(response.data["data"]["info_arr"].length>0){
              row_is_update=true;
              tmp_row_data=response.data["data"]["info_arr"][0];
            }
            write_area_ref.current.set_init_data({
              stu_info: stu_info,
              row_data: tmp_row_data,
              is_update: row_is_update,
              info_arr: response.data["data"]["info_arr"],
              is_view_mode:myProps.is_view_mode,
            });
          }
        } else {

        }
      });
  };

  return (
    <div>
      <WriteArea
        ref={write_area_ref}
        list={list}
        is_view_mode={myProps.is_view_mode}
        is_view_pre_btn={false}
      ></WriteArea>
    </div>
  );
});
export default CounselCareerArea;