import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import getXcolumnJson from "../../list/xcolumn/list";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function WritePopupContent(props: any) {
  let myProps = {
    isUpdate: false,
    rowData: {},
    stu_seq: "",
    stu_name: "",
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    list: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const fileWriteOneRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any>({
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_seq,
    "a_stu_name": myProps.stu_name,
    "a_sort1": "학생상담",
    "a_sort2": "예약",
    "a_is_public": "1",
    "a_kind": "학습",

    "a_counsel_date": myProps.select_date,
    "a_reserve_date": myProps.select_date,
    "a_writer_seq": user.user_seq,
    "a_writer": user.user_name,
    "a_par_id": "",
    "a_par_seq": "",
    "a_counseler_seq": user.user_seq,
    "a_counseler": user.user_name,
    "a_title": "상담",
    "a_content": "",
    "a_feedback": "",
    "a_eval_score": "",
    "a_reference": "",
    "a_is_success": "1",
    "a_state": "complete",
    ...myProps.rowData
  });

  let select_arr_a_kind: any = [];
  if (xColumnArr.select_arr.a_kind) {
    select_arr_a_kind = xColumnArr.select_arr.a_kind;
  }
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  useEffect(() => {

    if (props.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (props.rowData[key] != undefined) {
          changeRowData[key] = props.rowData[key];
        }
      }

      setRowData({
        ...rowData,
        ...changeRowData
      });
      ListOne(changeRowData);
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    let change_row_data: any = { ...rowData };
    if (name == "a_state") {
      if (value == "complete") {
        change_row_data["a_is_success"] = "1";
        if (strFunc.is_empty(change_row_data["a_counsel_date"])) {
          change_row_data["a_counsel_date"] = DateFunc.get_date_format(new Date(), "Y-m-d");
        }
      } else {
        change_row_data["a_is_success"] = "";
      }
    }

    change_row_data[name] = value;
    setRowData(change_row_data);
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const ListOne = (row_data: any) => {
    let row_pri_val = row_data["a_ymd"] + "," + row_data["a_seq"];
    let formJsonData = {
      "s_pri_arr": [row_pri_val],//[]
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            setRowData(response.data["data"]["info_arr"][0]);
          } else {
            alert("결과 정보 없음.");
            props.closePopup();
          }
        } else {
          alert(response.data["msg"]);
          props.closePopup();
        }
      });
  };

  const goWrite = useCallback((e: any) => {
    if (rowData.a_title == "") {
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };
    if (myProps.isUpdate == false) {
      wRowData["a_ymd"] = strFunc.str_replace("-", "", wRowData["a_reserve_date"]);
    }

    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (myProps.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let file_name_value = "";
          if (strFunc.is_empty(rowData.a_seq)) {
            let childFileInput = fileWriteOneRef.current.getFileInput();
            file_name_value = childFileInput.current.value;
          }
          if (file_name_value == "") {
            alert(response.data["msg"]);
            props.list({});
            props.closePopup();
          } else {
            let priVal = xColumnArr.func.getPriValByDataRow({
              xColumnArr: xColumnArr,
              rowData: response.data["data"][0]
            });
            fileWriteOneRef.current.goWrite({
              "a_par_seq": priVal
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  const goDelete = () => {
    if (strFunc.is_empty(rowData.a_seq)) {
      return false;
    }
    if (myProps.isUpdate == false) {
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let formJsonData = {
      "data_arr": [rowData],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/delete', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          props.list({});
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>
                {rowData.a_stu_name}
              </td>
              <th>상태</th>
              <td>
                <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange} >
                  <option value="ready">준비</option>
                  <option value="complete">완료</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>예정일</th>
              <td>
                <DatePicker
                  selected={rowData.a_reserve_date != "" ? new Date(rowData.a_reserve_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_reserve_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
              <th>상담일</th>
              <td>
                <DatePicker
                  selected={rowData.a_counsel_date != "" ? new Date(rowData.a_counsel_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_counsel_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td colSpan={3}>
                <SelectBoxBar
                  valueTextArr={select_arr_a_kind}
                  value={rowData.a_kind}
                  name={"a_kind"}
                  on_change={(inData: any) => {
                    handleInputChange({
                      target: {
                        name: inData.name,
                        value: inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>상담내용</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_content"
                  value={rowData.a_content}
                  onChange={handleInputChange}
                  placeholder={"내용"}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <th>피드백</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_feedback"
                  value={rowData.a_feedback}
                  onChange={handleInputChange}
                  placeholder={"피드백"}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <th>평가</th>
              <td colSpan={3}>
                <SelectBoxBar
                  valueTextArr={select_arr_eval_score}
                  value={rowData.a_eval_score}
                  name={"a_eval_score"}
                  on_change={(inData: any) => {
                    handleInputChange({
                      target: {
                        name: inData.name,
                        value: inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>참고내용</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_reference"
                  value={rowData.a_reference}
                  onChange={handleInputChange}
                  placeholder={"참고내용"}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                <span>
                  작성자: {rowData.a_writer}
                </span>
                <span className="ml-1">
                  작성일: {rowData.a_create_date}
                </span>
                <span className="ml-1">
                  수정일: {rowData.a_update_date}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        rowData.a_seq == "" &&
        <WriteInputFrame
          ref={fileWriteOneRef}
          init_data={{
            a_par_id: xColumnArr.table,
            a_par_seq: props.rowData.a_ymd + "," + props.rowData.a_seq,
          }}
          callback={() => { props.callback(); props.closePopup(); }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        {(myProps.isUpdate &&
          (user.user_grade == "master" || user.user_seq == rowData.a_writer_seq)
        ) &&
          <button className="btn btn-red" onClick={() => { goDelete(); }}>삭제</button>
        }
        <button className="btn btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
      {
        rowData.a_seq != "" &&
        <FileListWriteFrame
          listOpt={
            {
              s_par_id: xColumnArr.table,
              s_par_seq: xColumnArr.func.getPriValByDataRow({
                xColumnArr: xColumnArr,
                rowData: props.rowData
              }),
              s_start_date: rowData.a_reserve_date
            }
          }
        ></FileListWriteFrame>
      }
    </div>
  );
}

export default WritePopupContent;
