//import WeekListArea from "@/pages/comp/edu/month_prescribe/common/week_list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LygCalendarFunc from '@/lib/lyg/calendar';
import { default_info } from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import LoadingComponent from "@/pcomponents/common/content/loading";
import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewPopupContent from '@/pages/comp/edu/month_prescribe/popup/view_content';
import FindPrescribePopup from "@/pages/comp/edu/prescribe/prescribe_check/popup/find";
import FindMultyStaffPopup from "@/pages/comp/basic/user_staff/popup/find_multy";
import WriteCounselPopup from "@/pages/comp/basic/counsel/popup/write";
import { default_daily_report_last_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";
import TdContentArea from "./td_content";

const WeekListArea = forwardRef((props: any, ref) => {
  let myProps = {
    stu_info: null,
    last_daily_report_arr: [],
    last_mprescribe_arr: [],
    info_arr: [],
    set_info_arr: [],
    list: (inData: any) => { },
    set_refresh: () => { },
    select_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    is_view_mode: false,
    set_is_view_mode: (inData: any) => { },
    subject_name: "",
    comment_list: [],
    refresh_comment: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let select_date = myProps.select_date;
  let cal_data: any = LygCalendarFunc.get_cal_data_by_select_date(select_date);
  cal_data.select_month_week_len = Math.ceil((cal_data.select_month_json.first_day_of_week + cal_data.select_month_json.last_day) / 7);
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");

  //const [week_info_arr, set_week_info_arr] = useState<any>({});//{0:[],1:[]...}
  let week_info_arr: any = {};
  const [week_info_row_num_arr, set_week_info_row_num_arr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);//[0,0,0,0,0]
  //const [is_view_mode, set_is_view_mode] = useState(myProps.is_view_mode);
  let is_view_mode = myProps.is_view_mode;
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장 되었습니다.",
  });
  const auto_save_data = useRef<any>({
    elapse_time: 0,
    max_delay_time: 1000,
    is_exist_change: false,
    write_data_arr: [],
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view",//view
    isUpdate: false,
    rowData: {},
    row_num: 0,
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  // useEffect(() => {
  //   const auto_write_info_loop = setInterval(save_auto_write_info_loop, 1000);
  //   return () => {
  //     clearInterval(auto_write_info_loop);
  //   };
  // }, []);

  const save_auto_write_info_loop = () => {
    auto_save_data.current.elapse_time += 1000;
    if (auto_save_data.current.elapse_time > auto_save_data.current.max_delay_time) {
      if (auto_save_data.current.is_exist_change) {
        let tmp_auto_save_data = get_auto_save_write_data_arr();
        let row_is_reload = tmp_auto_save_data.is_reload;
        let row_write_data_arr = tmp_auto_save_data.write_data_arr;
        if (row_write_data_arr.length > 0) {
          go_write_week_info_arr_by_ajax({
            write_data_arr: row_write_data_arr,
            is_show_loading: false,
            is_reload: row_is_reload
          });
          auto_save_data.current.is_exist_change = false;
          auto_save_data.current.elapse_time = 0;
        }
      }
    }
  };

  const get_auto_save_write_data_arr = () => {
    let row_is_reload = false;
    let row_write_data_arr = [];
    for (let i = 0; i < auto_save_data.current.write_data_arr.length; i++) {
      let write_data_row = auto_save_data.current.write_data_arr[i];
      if (!strFunc.is_empty(write_data_row["a_lack"])) {
        row_write_data_arr.push(write_data_row);
        if (strFunc.is_empty(write_data_row["a_seq"])) {
          row_is_reload = true;
        }
      }
    }
    return {
      is_reload: row_is_reload,
      write_data_arr: row_write_data_arr
    };
  };

  const go_auto_save_data = () => {
    auto_save_data.current.elapse_time = auto_save_data.current.max_delay_time + 1000;
    auto_save_data.current.is_exist_change=true;
    save_auto_write_info_loop();
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    go_auto_save_data
  }));

  const get_info_arr_by_subject_name = (in_subject_name: string) => {
    if (strFunc.is_empty(in_subject_name)) {
      return [];
    }
    let info_arr_of_subject = [];
    for (let i = 0; i < myProps.info_arr.length; i++) {
      let info = myProps.info_arr[i];
      if (info["a_subject_name"] == in_subject_name) {
        info_arr_of_subject.push(info);
      }
    }
    return info_arr_of_subject;
  };
  let info_arr_of_subject = get_info_arr_by_subject_name(myProps.subject_name);

  const go_write_week_info_arr_by_default_row=(inData: any)=>{
    let opt_obj = {
      default_week_info: {},
      ...inData
    };
    let default_week_info=opt_obj.default_week_info;
    let tmp_write_data_opt=get_auto_save_write_data_arr();
    let tmp_is_reload=tmp_write_data_opt.is_reload;

    let is_exist_default_week_info=false;
    //write_data_arr에 기본데이터 있는지 확인
    for(let i=0;i<tmp_write_data_opt.write_data_arr.length;i++){
      let tmp_row_data=tmp_write_data_opt.write_data_arr[i];
      if(tmp_row_data["a_ymd"]==default_week_info["a_ymd"]
        &&tmp_row_data["a_seq"]==default_week_info["a_seq"]){
          if(!strFunc.is_empty(default_week_info["a_seq"])){
            is_exist_default_week_info=true;
          }
      }
    }
    if(is_exist_default_week_info==false){
      for(let i=0;i<tmp_write_data_opt.write_data_arr.length;i++){
        let tmp_row_data=tmp_write_data_opt.write_data_arr[i];
        if(tmp_row_data["a_reserve_date"]==default_week_info["a_reserve_date"]
          &&tmp_row_data["a_subject_name"]==default_week_info["a_subject_name"]
          &&tmp_row_data["a_writer_seq"]==default_week_info["a_writer_seq"]){
            is_exist_default_week_info=true;
        }
      }
    }

    if(tmp_write_data_opt.write_data_arr.length>0){
      if(is_exist_default_week_info==false){
        tmp_write_data_opt.write_data_arr.push(default_week_info);
        tmp_is_reload=true;
      }
      go_write_week_info_arr_by_ajax({ 
        write_data_arr: tmp_write_data_opt.write_data_arr,
        is_reload: tmp_is_reload
      });
    }else{
      if(strFunc.is_empty(default_week_info.a_seq)){
        tmp_is_reload=true;
      }
      go_write_week_info_arr_by_ajax({ 
        write_data_arr: [default_week_info],
        is_reload: tmp_is_reload
      });
    }
  };
  const go_write_week_info_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      write_data_arr: [],
      is_show_loading: true,
      is_reload: true,
      ...inData
    };
    let write_data_arr = opt_obj.write_data_arr;
    
    if (write_data_arr.length == 0) {
      for (let week_i in week_info_arr) {
        for (let i = 0; i < week_info_arr[week_i].length; i++) {
          write_data_arr.push(week_info_arr[week_i][i]);
        }
      }
    }
    if (write_data_arr.length == 0) {
      alert("데이터 선택이 없습니다.");
      return false;
    }
    // if(!confirm("저장 하시겠습니까?")){
    //   return false;
    // }
    let write_form_json: any = {
      "data_arr": write_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          auto_save_data.current.write_data_arr = [];
          if (opt_obj.is_show_loading) {
            set_loading_data({
              is_display: true,
              text: "저장 되었습니다.",
            });
            setTimeout(() => {
              set_loading_data({
                ...loading_data,
                is_display: false,
              });
            }, 1200);
          }

          // set_is_view_mode(true);
          if (opt_obj.is_reload) {
            myProps.list({});
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_week_info_by_ajax = (inData: any) => {
    let opt_obj = {
      data_arr: [],
      ...inData
    };

    let del_data_arr = opt_obj["data_arr"];
    for (let i = 0; i < del_data_arr.length; i++) {
      let del_data_row = del_data_arr[i];
      if (strFunc.is_empty(del_data_row["a_seq"])) {
        alert("데이터 키가 없습니다.");
        return false;
      }
    }
    if (del_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let write_form_json: any = {
      "data_arr": del_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/delete', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "삭제 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 1200);
          let new_info_arr=[];
          for(let i=0;i<myProps.info_arr.length;i++){
            let info=myProps.info_arr[i];
            let is_exist=false;
            for(let j=0;j<del_data_arr.length;j++){
              let del_data_row=del_data_arr[j];
              if(info["a_ymd"]==del_data_row["a_ymd"]
                &&info["a_seq"]==del_data_row["a_seq"]){
                  is_exist=true;
              }
            }
            if(is_exist==false){
              new_info_arr.push(info);
            }
          }
          myProps.set_info_arr(new_info_arr);
          if(auto_save_data.current.write_data_arr.length>0){
            let tmp_write_data_arr=[];
            for(let i=0;i<auto_save_data.current.write_data_arr.length;i++){
              let write_data_row=auto_save_data.current.write_data_arr[i];
              let is_exist=false;
              for(let j=0;j<del_data_arr.length;j++){
                let del_data_row=del_data_arr[j];
                if(write_data_row["a_ymd"]==del_data_row["a_ymd"]
                  &&write_data_row["a_seq"]==del_data_row["a_seq"]){
                    is_exist=true;
                }
              }
              if(is_exist==false){
                tmp_write_data_arr.push(write_data_row);
              }
            }
            auto_save_data.current.write_data_arr=tmp_write_data_arr;
          }
          //myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let td_data_arr = LygCalendarFunc.get_td_data_arr({
    cal_data: cal_data
  });
  let td_data_arr_len = td_data_arr.length;

  const get_new_month_prescribe_info = (inData: any) => {
    let opt_obj = {
      select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      ...inData,
    };
    let new_info = {
      ...default_info,
      a_ymd: DateFunc.get_date_format(new Date(opt_obj.select_date), "Ymd"),
      a_seq: "",
      a_reserve_date: DateFunc.get_date_format(new Date(opt_obj.select_date), "Y-m-d"),
      a_is_write: "1",
      a_write_date: DateFunc.get_date_format(new Date(opt_obj.select_date), "Y-m-d"),
      a_stu_seq: myProps.stu_info.a_seq,
      a_stu_name: myProps.stu_info.a_user_name,
      a_writer_seq: user.user_seq,
      a_writer_name: user.user_name,
      a_subject_name: myProps.subject_name,
      a_progress: "",
      a_create_seq: user.user_seq,
      a_create_date: DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"),
    };
    if (myProps.last_mprescribe_arr && myProps.last_mprescribe_arr.length > 0) {
      for (let i = 0; i < myProps.last_mprescribe_arr.length; i++) {
        let last_mprescribe_info = myProps.last_mprescribe_arr[i];
        if (last_mprescribe_info["a_subject_name"] == new_info["a_subject_name"]
          && last_mprescribe_info["a_writer_seq"] == new_info["a_writer_seq"]) {
          new_info["a_progress_reason"] = last_mprescribe_info["a_progress_reason"];
          new_info["a_progress"] = last_mprescribe_info["a_progress"];
          new_info["a_grow"] = last_mprescribe_info["a_grow"];
          new_info["a_homework_degree"] = last_mprescribe_info["a_homework_degree"];
          new_info["a_understand_degree"] = last_mprescribe_info["a_understand_degree"];
          new_info["a_study_amount"] = last_mprescribe_info["a_study_amount"];
          new_info["a_lesson_attitude"] = last_mprescribe_info["a_lesson_attitude"];
        }
      }
    }
    if (myProps.last_daily_report_arr && myProps.last_daily_report_arr.length > 0) {
      for (let i = 0; i < myProps.last_daily_report_arr.length; i++) {
        let last_report_info = myProps.last_daily_report_arr[i];
        if (last_report_info["a_subject"] == new_info["a_subject_name"]) {
          new_info["a_progress"] = last_report_info["a_textbook"] + " " + last_report_info["a_textbook_study"];
        }
      }
    }

    return new_info;
  };
  let week_td_arr = LygCalendarFunc.get_td_week_data_arr({
    cal_data: cal_data,
    td_data_arr: td_data_arr,
    standard_day_of_week: 1
  });

  const get_week_info_arr_data = () => {
    let info_arr_len = info_arr_of_subject.length;
    let week_info_arr_data: any = {};

    for (let week_i = 0; week_i < week_td_arr.length; week_i++) {
      let week_td = week_td_arr[week_i];
      week_info_arr_data[week_i] = [];
      for (let td_i = 0; td_i < week_td["row_td_data_arr"].length; td_i++) {
        let td_data = week_td["row_td_data_arr"][td_i];
        for (let i = 0; i < info_arr_len; i++) {
          let info = info_arr_of_subject[i];
          if (td_data["date_str"] == info["a_reserve_date"]) {
            week_info_arr_data[week_i].push(info);
          }
        }
      }
    }

    let add_new_info_arr = [];
    for (let week_i = 0; week_i < week_td_arr.length; week_i++) {
      let week_td = week_td_arr[week_i];

      //기본 1개는 추가.
      if (week_info_arr_data[week_i] != undefined) {
        if (week_info_arr_data[week_i].length == 0) {
          //오늘날짜가 포함이라면 오늘 날짜로 추가.
          let start_date_num = parseInt(strFunc.getNumber2(week_td["start_date"]));
          let end_date_num = parseInt(strFunc.getNumber2(week_td["end_date"]));
          let now_date_num = parseInt(strFunc.getNumber2(cal_data.now_date_str));
          let select_date_str = week_td["start_date"];
          if (start_date_num <= now_date_num && now_date_num <= end_date_num) {
            select_date_str = cal_data.now_date_str;
          } else {
            if (week_td["start_day_of_week"] == 0) {
              //월요일은피하자.
              select_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str), 'day', +1), "Y-m-d");
            }
          }
          let add_new_info = get_new_month_prescribe_info({
            select_date: select_date_str
          });
          week_info_arr_data[week_i].push(add_new_info);
          add_new_info_arr.push(add_new_info);
        }
      }
    }
    if (add_new_info_arr.length > 0) {
      // myProps.set_info_arr([
      //   ...myProps.info_arr,
      //   ...add_new_info_arr
      // ]);
    }

    return week_info_arr_data;
  };
  week_info_arr = get_week_info_arr_data();

  const get_week_colgroup_tags = () => {
    let col_per_num = 100;
    if (week_td_arr.length > 0) {
      col_per_num = Math.floor(100 / week_td_arr.length);
    }
    let colgroup_tags = week_td_arr.map((item: any, idx: number) => {
      return (
        <col key={idx} width={col_per_num} />
      );
    });

    return colgroup_tags;
  };

  const on_change_week_info = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      week_idx: 0,
      week_idx_row_num: 0,
      callback_info: null,
      ...inData,
    };
    let name = opt_obj.name;
    let value = opt_obj.value;
    let change_week_info_arr = { ...week_info_arr };
    if (change_week_info_arr[opt_obj.week_idx] == undefined) {
      return false;
    }
    if (change_week_info_arr[opt_obj.week_idx].length == 0) {
      return false;
    }
    let week_idx_row_num = opt_obj.week_idx_row_num;
    let row_data = change_week_info_arr[opt_obj.week_idx][week_idx_row_num];
    if (name == "a_content" && !strFunc.is_empty(value) && row_data["a_is_success"] != "1") {
      row_data["a_is_success"] = "1";
      row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      row_data["a_success_writer_seq"] = user.user_seq;
      row_data["a_success_writer_name"] = user.user_name;
    }
    if (name == "a_success_check_msg" && !strFunc.is_empty(value) && row_data["a_is_success_check"] != "1") {
      row_data["a_is_success_check"] = "1";
      row_data["a_success_check_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    }
    if (name == "a_is_success" && value == "1") {
      if (row_data[name] != "1") {
        row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        row_data["a_success_writer_seq"] = user.user_seq;
        row_data["a_success_writer_name"] = user.user_name;
      }
    }
    if (name == "a_is_success_check" && value == "1") {
      if (row_data[name] != "1") {
        row_data["a_success_check_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }
    if (name == "a_is_request_counsel" && value == "1") {
      if (strFunc.is_empty(row_data["a_request_counselor_seq"])) {
        value = "";
      }
      if (row_data[name] != "1") {
        row_data["a_request_counsel_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }
    if (name == "a_subject_name") {
      update_subject_name_of_writer_by_ajax({
        subject_name: value
      });
    }
    if (name == "a_request_counselor_seq") {
      if (!strFunc.is_empty(opt_obj.callback_info)) {
        row_data["a_is_request_counsel"] = "1";
        row_data["a_request_counsel_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        row_data["a_request_counselor_seq"] = opt_obj.callback_info["a_seq"];
        row_data["a_request_counselor"] = opt_obj.callback_info["a_user_name"];
        row_data["a_request_counsel_writer_seq"] = user.user_seq;
        row_data["a_request_counsel_writer"] = user.user_name;
      } else if (value == "") {
        row_data["a_is_request_counsel"] = "";
        row_data["a_request_counselor_seq"] = "";
        row_data["a_request_counselor"] = "";
      }
    }
    row_data[name] = value;
    change_week_info_arr[opt_obj.week_idx][week_idx_row_num] = row_data;

    let change_update_by_name_arr = [
      "a_is_success",
      "a_is_success_check",
      "a_is_request_counsel",
      "a_request_counselor_seq",
      "a_content",
    ];
    if (strFunc.str_in_array(name, change_update_by_name_arr) != -1) {
      let tmp_is_reload=false;
      if(strFunc.is_empty(row_data["a_seq"])){
        tmp_is_reload=true;
      }
      go_write_week_info_arr_by_ajax({
        write_data_arr: [row_data],
        is_show_loading: false,
        is_reload: tmp_is_reload
      });
    }

    //auto save (해당 줄만 적용)
    auto_save_data.current.is_exist_change = true;
    auto_save_data.current.elapse_time = 0;
    auto_save_data.current.write_data_arr = get_add_auto_write_data_arr({
      add_write_data_arr: change_week_info_arr[opt_obj.week_idx]
    });

    //데이터 모으기
    let change_info_arr = [...myProps.info_arr];
    let is_exist_change_row = false;
    for (let i = 0; i < change_info_arr.length; i++) {
      let change_info = change_info_arr[i];

      if (change_info["a_ymd"] == row_data["a_ymd"]
        && change_info["a_seq"] == row_data["a_seq"]
        && change_info["a_subject_name"] == row_data["a_subject_name"]
        && change_info["a_reserve_date"] == row_data["a_reserve_date"]
        && change_info["a_create_seq"] == row_data["a_create_seq"]) {
        change_info_arr[i] = row_data;
        is_exist_change_row = true;
      }
    }
    if (is_exist_change_row == false) {
      change_info_arr.push(row_data);
    }
    myProps.set_info_arr(change_info_arr);
  };

  const get_add_auto_write_data_arr = (inData: any) => {
    let opt_obj = {
      add_write_data_arr: [],
      ...inData
    };
    let change_write_data_arr = [...auto_save_data.current.write_data_arr];
    let add_write_data_arr = opt_obj.add_write_data_arr;
    for (let i = 0; i < add_write_data_arr.length; i++) {
      let add_data = add_write_data_arr[i];
      let is_exist = false;
      for (let j = 0; j < change_write_data_arr.length; j++) {
        let change_data = change_write_data_arr[j];
        if (!strFunc.is_empty(change_data["a_seq"])
          && change_data["a_ymd"] == add_data["a_ymd"]
          && change_data["a_seq"] == add_data["a_seq"]) {
          is_exist = true;
        } else if (change_data["a_subject_name"] == add_data["a_subject_name"]
          && change_data["a_reserve_date"] == add_data["a_reserve_date"]
          && change_data["a_create_seq"] == add_data["a_create_seq"]) {
          is_exist = true;
        }
      }
      if (is_exist == false) {
        change_write_data_arr.push(add_data);
      }
    }

    return change_write_data_arr;
  };

  const update_subject_name_of_writer_by_ajax = (inData: any) => {
    let opt_obj = {
      subject_name: "",
      ...inData
    };
    if (strFunc.is_empty(opt_obj["subject_name"])) {
      return false;
    }
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let up_row_data = {
      a_seq: user.user_seq,
      a_main_subject: opt_obj["subject_name"],
    };
    let formJsonData = {
      "data_arr": [up_row_data],
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {

        }
      });
  };

  const openOnePopup = (inData: any) => {
    let opt_obj = {
      sort: "view",
      isUpdate: false,
      rowData: {},
      week_i: "",
      ...inData
    };
    let isUpdate = opt_obj["isUpdate"];
    let sort = opt_obj["sort"];
    let pop_title = "등록";
    let tmp_rowData = opt_obj["rowData"];
    if (sort == "view") {
      pop_title = "보기";
    }
    tmp_rowData.month_num = cal_data.select_month_json.m;
    tmp_rowData.week_i = opt_obj.week_i;
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort,
      width: "800px",
      height: "80%",
    });
  };

  const openFindPrescribePopup = (inData: any) => {
    let opt_obj = {
      sort: "find_pres",
      row_num: 0,
      ...inData,
    };
    let pop_title = "찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      row_num: opt_obj.row_num,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataFindPrescribe = (inData: any) => {
    let opt_obj = {
      cate_info: null,
      info_arr: [],
      select_check_idx: 0,
      select_cate_idx: 0,
      row_num: 0,
      key_str: "",
      pri_val: "",
      ...inData,
    };
    if (opt_obj["cate_info"] == null) {
      return false;
    }
    let week_info: any = null;
    let row_num = opt_obj["row_num"];
    let week_idx_row_num = 0;
    if (week_info_row_num_arr[row_num]) {
      week_idx_row_num = week_info_row_num_arr[row_num];
    }
    if (week_info_arr[row_num] && week_info_arr[row_num].length > 0) {
      if (week_idx_row_num > week_info_arr[row_num].length - 1) {
        week_idx_row_num = 0;
      }
      week_info = week_info_arr[row_num][week_idx_row_num];
    }
    if (week_info == null) {
      return false;
    }
    let check_info_arr = opt_obj["info_arr"];
    if (check_info_arr.length == 0) {
      return false;
    }
    let check_info = check_info_arr[0];
    let value_str = (opt_obj["select_cate_idx"] + 1) + ") " + opt_obj["cate_info"]["a_title"];
    value_str += "-" + check_info["a_title"];//(opt_obj["select_check_idx"]+1)
    on_change_week_info({
      name: "a_grow",
      value: value_str,
      week_idx: row_num,
      week_idx_row_num: week_idx_row_num
    });
  };

  const openFindCounselorePopup = (inData: any) => {
    let opt_obj = {
      sort: "find_counselor",
      row_num: 0,
      ...inData,
    };
    let pop_title = "찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      row_num: opt_obj.row_num,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataFindCounselor = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key_str: "",
      pri_val: "",
      ...inData,
    };
    if (opt_obj["info_arr"].length == 0) {
      return false;
    }
    let week_info: any = null;
    let row_num = opt_obj["row_num"];
    let week_idx_row_num = 0;
    if (week_info_row_num_arr[row_num]) {
      week_idx_row_num = week_info_row_num_arr[row_num];
    }
    if (week_info_arr[row_num] && week_info_arr[row_num].length > 0) {
      if (week_idx_row_num > week_info_arr[row_num].length - 1) {
        week_idx_row_num = 0;
      }
      week_info = week_info_arr[row_num][week_idx_row_num];
    }
    if (week_info == null) {
      return false;
    }
    let staff_info = opt_obj["info_arr"][0];
    let value_str = staff_info["a_seq"];
    on_change_week_info({
      name: "a_request_counselor_seq",
      value: value_str,
      week_idx: row_num,
      week_idx_row_num: week_idx_row_num,
      callback_info: staff_info
    });
  };

  const openWriteCounselPopup = (inData: any) => {
    let opt_obj = {
      sort: "write_counsel",
      row_pri_val: "",
      row_data: {},
      ...inData,
    };
    let row_data = opt_obj.row_data;
    let row_pri_val = opt_obj.row_pri_val;

    if (strFunc.is_empty(row_data.a_seq)) {
      return false;
    }
    if (strFunc.is_empty(row_data.a_stu_seq)) {
      alert("학생을 선택해주세요.");
      return false;
    }
    let row_is_update = false;
    let tmp_row_data: any = {
      a_par_id: "month_prescribe",
      a_par_seq: row_pri_val,
      a_content: MonthPrescribeViewFunc.get_view_text_str({ info: row_data })
    };
    let pop_title = "상담등록";
    if (!strFunc.is_empty(row_data.a_request_counsel_seq)) {
      let counsel_pri_val_arr = row_data.a_request_counsel_seq.split(",");
      if (counsel_pri_val_arr.length == 2) {
        tmp_row_data = {
          a_ymd: counsel_pri_val_arr[0],
          a_seq: counsel_pri_val_arr[1],
        };
        row_is_update = true;
        pop_title = "상담수정";
      }
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      isUpdate: row_is_update,
      rowData: tmp_row_data,
      width: "800px",
      height: "80%",
    });
  };

  const get_week_tr_td_tags = (inData: any) => {
    let opt_obj = {
      name: "",
      ...inData
    };
    let week_td_tags = week_td_arr.map((item: any, idx: number) => {
      let week_info: any = {};
      let week_idx_row_num = 0;
      let is_now_week = false;
      let week_date_str_arr=[];
      for (let i = 0; i < item.row_td_data_arr.length; i++) {
        week_date_str_arr.push(item.row_td_data_arr[i].date_str);
        if (item.row_td_data_arr[i].date_str == now_date_str) {
          is_now_week = true;
        }
      }

      if (week_info_row_num_arr[idx]) {
        week_idx_row_num = week_info_row_num_arr[idx];
      }
      if (week_info_arr[idx] != undefined && week_info_arr[idx].length > 0) {
        if (week_idx_row_num > week_info_arr[idx].length - 1) {
          week_idx_row_num = 0;
        }
        week_info = week_info_arr[idx][week_idx_row_num];
      }

      let row_td_style: any = { border: "1px solid #ccc", verticalAlign: "top" };
      if (week_info.a_is_success == "1") {
        row_td_style["background"] = "#fbfffb";
      }
      if (is_now_week) {
        row_td_style["border"] = "2px solid #49A894";
      }
      let writer_span_style: any = {};
      if (week_info.a_is_success == "1" && week_info.a_is_success_check != "1") {
        writer_span_style["background"] = "#ec634c";
        writer_span_style["color"] = "#ffffff";
        writer_span_style["borderRadius"] = "5px";
      }

      let last_daily_report_info:any={...default_daily_report_last_info};
      
      if(week_info.daily_info_arr&&week_info.daily_info_arr.length>0){
        last_daily_report_info=week_info.daily_info_arr[0];
      }

      //그래도없다면 처음꺼가져오기(오늘날짜보다 많고)
      if(strFunc.is_empty(last_daily_report_info["a_stu_seq"])&&myProps.last_daily_report_arr){
        let reverse_week_date_str_arr=[...week_date_str_arr].reverse();
        if(strFunc.is_empty(last_daily_report_info["a_stu_seq"])
          &&reverse_week_date_str_arr[0]>now_date_str){
          for (let i = 0; i < myProps.last_daily_report_arr.length; i++) {
            let tmp_last_report_info = myProps.last_daily_report_arr[i];
            //과목 맞으면 최신 일일보고서
            if (tmp_last_report_info["a_subject"] == myProps.subject_name) {
              //이미있다면 그만
              if(strFunc.is_empty(last_daily_report_info["a_stu_seq"])){
                last_daily_report_info=tmp_last_report_info;
              }
            }
          }
        }
      }

      return (
        <td key={idx} className="text-center" style={row_td_style}>
          {opt_obj["name"] == "" &&
            <div>
              {item.idx + 1} 주차
              <span className="ml-1" style={{ color: "gray" }}>
                ({item.start_date.substring(8, 10)}일
                ~)
                {/* {item.end_date.substring(8, 10)}일) */}
              </span>
              {is_view_mode == false &&
                <button className="btn-s btn-sky ml-1" 
                  onClick={() => {
                    go_write_week_info_arr_by_default_row({
                      default_week_info:week_info
                    });
                  }}>저장</button>
              }
              {(is_view_mode == false && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-red ml-1" onClick={() => { delete_week_info_by_ajax({ data_arr: [week_info] }); }}>삭제</button>
              }

              {(is_view_mode && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-dark ml-1"
                  onClick={() => { openOnePopup({ sort: "view", rowData: week_info, week_i: item.idx }); }} >복사</button>
              }

              {!strFunc.is_empty(week_info.a_seq) &&
                <div>
                  {(week_info_arr[idx] != undefined && week_info_arr[idx].length > 0) &&
                    <span>
                      {week_info_arr[idx].map((row_week_info: any, row_week_info_idx: number) => {
                        let row_week_info_class_name = "btn-s btn-gray ml-1";
                        if (row_week_info_idx == week_idx_row_num) {
                          row_week_info_class_name = "btn-s btn-sky ml-1";
                        }
                        let row_week_info_title_str = row_week_info["a_reserve_date"] + " " + row_week_info["a_writer_name"];
                        return (
                          <button key={row_week_info_idx} className={row_week_info_class_name}
                            onClick={() => {
                              if (row_week_info_idx != week_idx_row_num) {
                                let change_week_info_row_num_arr = [...week_info_row_num_arr];
                                change_week_info_row_num_arr[idx] = row_week_info_idx;
                                set_week_info_row_num_arr(change_week_info_row_num_arr);
                                myProps.refresh_comment(inData);
                                myProps.list();
                              }
                            }}
                            title={row_week_info_title_str}>{row_week_info["a_reserve_date"].substring(8, 10)}</button>
                        );
                      })}
                    </span>
                  }
                  <span className="ml-1">
                    작성:
                    <span className="ml-2" style={writer_span_style}>
                      {week_info.a_writer_name}
                    </span>
                  </span>
                </div>
              }
            </div>
          }
          {opt_obj["name"] != "" &&
            <TdContentArea
              name={opt_obj["name"]}
              week_info={week_info}
              week_idx={idx}
              week_idx_row_num={week_idx_row_num}
              on_change_week_info={on_change_week_info}
              is_view_mode={is_view_mode}
              go_write_week_info_arr_by_default_row={go_write_week_info_arr_by_default_row}
              openFindPrescribePopup={openFindPrescribePopup}
              openFindCounselorePopup={openFindCounselorePopup}
              openWriteCounselPopup={openWriteCounselPopup}
              comment_list={myProps.comment_list}
              refresh_comment={(inData: any) => {
                myProps.refresh_comment(inData);
                myProps.list();
              }}
              last_daily_report_info={last_daily_report_info}
              is_now_week={is_now_week}
            ></TdContentArea>
          }
        </td>
      );
    });

    return week_td_tags;
  };

  return (
    <div>
      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
        {myProps.subject_name}
        {/* <button className="btn btn-dark ml-1" onClick={()=>{go_write_week_info_arr_by_ajax({});}}>저장</button> */}
        {/* <button className="btn btn-dark ml-2" onClick={() => { myProps.set_is_view_mode(!is_view_mode); }}>
          {is_view_mode ? "수정모드" : "보기모드"}
        </button> */}
      </div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            {get_week_colgroup_tags()}
          </colgroup>
          <tbody>
            <tr>
              {get_week_tr_td_tags({})}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_progress_reason" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_progress" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_grow" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_lack" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_content" })}
            </tr>
          </tbody>
        </table>
      </div>
      {loading_data.is_display &&
        <LoadingComponent text={loading_data.text}></LoadingComponent>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData}
              callback={() => { myProps.set_refresh({}); }}
              is_view_write_btn={false}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewPopupContent>
          }
          {popupData.sort == "find_pres" &&
            <FindPrescribePopup
              row_num={popupData.row_num}
              callBackData={callBackDataFindPrescribe}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindPrescribePopup>
          }
          {popupData.sort == "find_counselor" &&
            <FindMultyStaffPopup
              row_num={popupData.row_num}
              callBackData={callBackDataFindCounselor}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindMultyStaffPopup>
          }
          {popupData.sort == "write_counsel" &&
            <WriteCounselPopup
              rowData={popupData.rowData}
              isUpdate={popupData.isUpdate}
              stu_seq={myProps.stu_info.a_seq}
              stu_name={myProps.stu_info.a_user_name}
              list={(inData: any) => {
                myProps.list({});
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteCounselPopup>
          }
        </LayerPopup>
      }
    </div>
  );
});
export default WeekListArea;