//import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '@/pages/main/basic/file/popup/write';
import ViewPopupContent from '@/pages/main/basic/file/popup/view';
import Paging from '@/pcomponents/common/crud/list/paging/paging';
import ListComponent from "@/pcomponents/common/crud/list/list/list_component";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import getXcolumnJson from "./xcolumn/list";
import pdf_ico_img from "@/img/ico/pdf_ico.png";
import { useSelector, useDispatch } from 'react-redux';

const FileListWriteFrame = forwardRef((props: any, ref) => {
  let myProps = {
    "isView": false,
    "listOpt": {
      "s_start_date": "",
      's_par_id': "",
      's_par_seq': "",
      's_sort1': "",
      's_sort2': "",
      's_sort3': "",
    },
    "callback": (inData: any) => { },
    ...props
  };
  const user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listComponentRef = useRef<any>(null);
  const downloadFormRef = useRef<HTMLFormElement>(null);
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 10,
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_start_date': props.listOpt.s_start_date,
      's_end_date': '',
      'is_add_idx_info': "1",
      's_par_id': myProps.listOpt.s_par_id,
      's_par_seq': myProps.listOpt.s_par_seq,
      's_sort1': myProps.listOpt.s_sort1 ? props.listOpt.s_sort1 : "",
      's_sort2': myProps.listOpt.s_sort2 ? props.listOpt.s_sort2 : "",
      's_sort3': myProps.listOpt.s_sort3 ? props.listOpt.s_sort3 : "",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const customCellRenderer = (params: any) => {
    let row_val: any = "";
    if (params.rowData[params.key] != undefined) {
      row_val = params.rowData[params.key];
    }
    if (params.key == "row_view_thum") {
      if (params.rowData["is_image"] == "1") {
        row_val =
          <div className="text-center">
            <a href="#" onClick={() => { setTimeout(() => { openOnePopup(true, "view"); }, 100); }} >
              <img src={params.rowData.thum_img_url} style={{ height: 25 }} />
            </a>
          </div>;
      } else if (params.rowData["a_ext"].toUpperCase() == "PDF") {
        row_val =
          <div className="text-center">
            <a href="#" onClick={() => { setTimeout(() => { window.open(params.rowData["img_url"]); }, 100); }} >
              <img src={pdf_ico_img} style={{ height: 25 }} />
            </a>
          </div>;
      }
    } else if (params.key == "a_oriname") {
      row_val =
        <a href="#" onClick={() => { setTimeout(() => { openOnePopup(true, "view"); }, 100); }} >
          {row_val}
        </a>;
    } else if (params.key == "row_view_down") {
      row_val =
        <div className="text-center">
          <button className="btn-s btn-round-green" onClick={() => { setTimeout(() => { goDownLoad(); }, 100); }} >
            다운
          </button>
        </div>;
    }
    return row_val;
  };
  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    title: "팝업",
    width: "700px",
    height: "70%",
  });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setPageData, list
  }));

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let list_form_data:any={...pageData.listOpt};
    list_form_data["s_comp_seq"]=user.comp_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          if (listComponentRef.current != null) {
            listComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
          }
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    } else if (selected_row.length >= 2) {
      alert("한개만 선택해주세요.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": selected_row[0]["a_ymd"],
      "a_seq": selected_row[0]["a_seq"],
    };
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        myProps.callback({infoArr});
        list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup = (isUpdate: any, sort: string) => {
    if (isUpdate == undefined) { isUpdate = false; }
    if (sort == undefined) { sort = "write"; }
    let tmp_rowData = {};
    let pop_title = "등록";
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = listComponentRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const goDownLoad = () => {
    let selected_row = listComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    } else if (selected_row.length >= 2) {
      alert("한개만 선택해주세요.");
      return false;
    }
    let pri_val = selected_row[0]["a_ymd"] + "," + selected_row[0]["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = selected_row[0]["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };

  return (
    <div>
      <h5 className="con-p-gray">첨부파일 ({countInfo["tot"]} 건) </h5>
      <div className="btn-box-left">
        {myProps.isView == false &&
          <>
            <button className="btn btn-blue" onClick={() => { openOnePopup(false, "write"); }} >등록</button>
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
          </>
        }
      </div>
      <ListComponent
        ref={listComponentRef}
        infoArr={infoArr}
        xColumnArr={xColumnArr}
        gridOpt={
          {
            td_align: "center",
            height: "30vh",
            is_add_checkbox: true,
            customCellRenderer: customCellRenderer
          }
        }></ListComponent>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: any) => { list({ now_page: now_page }); }}></Paging>
      </div>
      <form id="download_form" ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData}
              callback={() => { list({}); myProps.callback(infoArr); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              init_data={{
                "a_par_id": pageData.listOpt.s_par_id,
                "a_par_seq": pageData.listOpt.s_par_seq,
                "a_sort1": pageData.listOpt.s_sort1,
                "a_sort2": pageData.listOpt.s_sort2,
                "a_sort3": pageData.listOpt.s_sort3,
              }}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
});

export default FileListWriteFrame;