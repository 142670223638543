import React,{ useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";

const ParentArea = forwardRef((props:any, ref) => {
  let myProps={
    is_update:false,
    info:{},
    parent_arr:[{
      "a_seq":"",
      "a_user_phone":"",
      "a_user_id":"",
      "a_user_pw":"",
      "a_relation":"모",
      "a_order":"0",
      "a_is_attend_sms":"1",
      "a_is_attend_push":"1",
      "a_is_attend_katalk":"1",
    }],
    setParentArr:(inData:any)=>{},
    on_change_parent_hp:(inData:any)=>{},
    ...props,
  };
  let parentArr=myProps.parentArr;

  useEffect(()=>{
    
  },[]);
  
  const handleInputChange=(event:any,idx:number)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="a_user_phone"){
      value=strFunc.autoHypenPhone(value);
      checkPhoneOfParentUser(value,idx);
      if(idx==0){
        if(value.length>10){
          myProps.on_change_parent_hp(value);
        }
      }
    }
    let tmpParentArr=[...parentArr];
    tmpParentArr[idx][name]=value;
    myProps.setParentArr([
      ...tmpParentArr
    ]);
  };

  const setParentArrByLinkArr=(link_user_arr:any)=>{
    let tmpParentArr=[];
    for(let i=0;i<link_user_arr.length;i++){
      let link_info=link_user_arr[i];
      if(link_info["par_info"]&&link_info["stu_info"]){
        tmpParentArr.push({
          "a_seq":link_info["par_info"]["a_seq"],
          "a_user_phone":strFunc.autoHypenPhone(link_info["par_info"]["a_user_phone"]),
          "a_user_id":link_info["par_info"]["a_user_id"],
          "a_user_pw":"",
          "a_relation":link_info["a_relation"],
          "a_order":link_info["a_order"],
          "a_is_attend_sms":link_info["a_is_attend_sms"],
          "a_is_attend_push":link_info["a_is_attend_push"],
          "a_is_attend_katalk":link_info["a_is_attend_katalk"],
        });
      }
    }
    myProps.setParentArr(tmpParentArr);
  };

  const checkPhoneOfParentUser=(phone:string,idx:number)=>{
    if(phone.length!=13){
      return false;
    }
    if(parentArr[idx].a_seq==""){
      
      //이미 있는지 체크
      let checkPhoneFormData={
        "s_phone":phone,
        "s_grade":"parent",
        "is_need_count":"",
        "is_need_info_arr":"1",
        "is_no_limit":"1",
      };
      my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',checkPhoneFormData,get_axios_data())
      .then((response) => {
        if(response.data["result"]==="true"){
          //props.callback();
          if(response.data["data"]["info_arr"].length!=0){
            let tmpParentArr:any=[...parentArr];
            let u_info=response.data["data"]["info_arr"][0];
            if(confirm("해당 핸드폰은 이미있는 회원이 있습니다. "+u_info["a_user_name"]+" 님이 맞습니까?")){
              tmpParentArr[idx]["a_seq"]=u_info["a_seq"];
              tmpParentArr[idx]["a_user_id"]=u_info["a_user_id"];
            }else{
              tmpParentArr[idx]["a_user_phone"]="";
            }
            myProps.setParentArr([
              ...tmpParentArr
            ]);
          }
        }
      });
    }
  };

  const addRow=()=>{
    let tmpParentArr=[...parentArr];
    tmpParentArr.push({
      "a_seq":"",
      "a_user_phone":"",
      "a_relation":"",
      "a_user_id":"",
      "a_user_pw":"",
      "a_order":tmpParentArr.length,
      "a_is_attend_sms":"",
      "a_is_attend_push":"",
      "a_is_attend_katalk":"",
    });
    myProps.setParentArr([
      ...tmpParentArr
    ]);
  };

  const setOrderNumAuto=()=>{
    let tmpParentArr=[...parentArr];
    for(let i=0;i<tmpParentArr.length;i++){
      tmpParentArr[i].a_order=i;
    }
    myProps.setParentArr([
      ...tmpParentArr
    ]);
  };

  const delRow=(idx:number)=>{
    let tmpParentArr=[...parentArr];
    if(tmpParentArr[idx].a_seq==""){
      //저장전에는 바로삭제
      delRowByIdx(idx);
    }else{
      if(!confirm("부모회원을 삭제 하시겠습니까?")){
        return false;
      }
      let delFormJsonData={
        "user_seq":tmpParentArr[idx].a_seq
      };
      my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/delete_one',delFormJsonData,get_axios_data())
      .then((response) => {
        if(response.data["result"]==="true"){
          props.callback();
          delRowByIdx(idx);
        }else{
          alert(response.data["msg"]);
        }
      });
    }
  };

  const delRowByIdx=(idx:number)=>{
    let tmpParentArr=[...parentArr];
    let newParentArr=[];
    for(let i=0;i<tmpParentArr.length;i++){
      if(i!=idx){
        newParentArr.push(tmpParentArr[i]);
      }
    }
    myProps.setParentArr([
      ...newParentArr
    ]);
  };

  const changeRowOrder=(now_idx:number,change_idx:number)=>{
    if(change_idx<0){
      return false;
    }
    if(change_idx>=parentArr.length){
      return false;
    }
    let tmpParentArr=[...parentArr];
    let now_row=tmpParentArr[now_idx];
    let change_row=tmpParentArr[change_idx];
    tmpParentArr[now_idx]=change_row;
    tmpParentArr[change_idx]=now_row;
    for(let i=0;i<tmpParentArr.length;i++){
      tmpParentArr[i].a_order=i;
    }
    myProps.setParentArr([
      ...tmpParentArr
    ]);
    return true;
  }

  return (
    <div>
      <div className="mt-2">
        ※ 학부모 
        <button className="btn btn-gray ml-2" onClick={addRow}>추가</button>
      </div>
      <div className="list-table-div mt-1">
          <table>
            <colgroup>
              <col width="50px" />
              <col width="110px" />
              <col width="80px" />
              <col width="80px" />
              <col width="50px" />
            </colgroup>
            <thead>
              <tr>
                <th>관계</th>
                <th>연락처</th>
                <th>아이디</th>
                <th>비밀번호</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {parentArr.map((item:any,idx:number)=>{
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td className="text-center">
                        <select className="px-1" name="a_relation" value={item.a_relation} onChange={(e:any)=>{handleInputChange(e,idx);}} style={{width:"99%"}} >
                          <option value="">선택없음</option>
                          <option value="모">모</option>
                          <option value="부">부</option>
                          <option value="etc">기타</option>
                        </select>
                      </td>
                      <td className="text-center">
                        <input type="text"  className="px-1" name="a_user_phone" value={item.a_user_phone} onChange={(e:any)=>{handleInputChange(e,idx);}} autoComplete="off" style={{width:"99%"}} />
                      </td>
                      <td className="text-center">
                        <input type="text"  className="px-1" name="a_user_id" value={item.a_user_id} onChange={(e:any)=>{handleInputChange(e,idx);}} autoComplete="off" style={{width:"99%"}}/>
                      </td>
                      <td className="text-center">
                        <input type="password"  className="px-1" name="a_user_pw" value={item.a_user_pw} onChange={(e:any)=>{handleInputChange(e,idx);}} autoComplete="off" style={{width:"99%"}}/>
                      </td>
                      <td className="text-center">
                        <div className="btn-box-center">
                          <button className="btn-s btn-dark" onClick={()=>{changeRowOrder(idx,idx-1);}}>▲</button>
                          <button className="btn-s btn-dark" onClick={()=>{changeRowOrder(idx,idx+1);}}>▼</button>
                          <button className="btn-s btn-dark" onClick={()=>{delRow(idx);}}>X</button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={99} className="text-center bg-stone-200" >
                        <span className="px-2 text-emerald-400 font-bold">알림:</span>
                        <label className="px-2">
                          <input type="checkbox" className="px-1" name="a_is_attend_sms" value="1" onChange={(e:any)=>{handleInputChange(e,idx);}}
                            checked={item.a_is_attend_sms=="1"} />
                          <span className="ml-1">문자</span>
                        </label>
                        <label className="px-2">
                          <input type="checkbox" className="px-1" name="a_is_attend_push" value="1" onChange={(e:any)=>{handleInputChange(e,idx);}}
                            checked={item.a_is_attend_push=="1"} />
                          <span className="ml-1">푸시</span>
                        </label>
                        <label className="px-2" >
                          <input type="checkbox" className="px-1" name="a_is_attend_katalk" value="1" onChange={(e:any)=>{handleInputChange(e,idx);}}
                            checked={item.a_is_attend_katalk=="1"} />
                          <span className="ml-1">알림톡</span>
                        </label>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
    </div>
  );
});
export default ParentArea;