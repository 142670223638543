import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { add_memo_select_arr } from "@/pages/comp/basic/student/list/area/write/data/default_data";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import noimg_img from "@/img/ico/noimg.png";

function CounselInfoArea(props: any) {
  let myProps = {
    info: {},
    add_memo_info: {},
    set_add_memo_info: (inData: any) => { },
    is_update: false,
    is_view_mode: false,
    refresh_data: () => { },
    Style: {},
    ...props
  };
  let info = myProps.info;
  let add_memo_info = myProps.add_memo_info;
  let is_view_mode = myProps.is_view_mode;
  let Style = myProps.Style;
  let user = useSelector((state: any) => state.user);

  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);

  useEffect(() => {
    
  }, []);

  const handleInputChange2 = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_add_memo_info({
      ...add_memo_info,
      ...{ [name]: value }
    });
  };

  const goCounselfileWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    let default_file_row_data = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": info.a_seq,
      "a_sort1": "counsel",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    if (!confirm("상담 사진을 저장 하시겠습니까?")) {
      return false;
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify([file_row_data]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goCounselFileDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("상담 사진을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        //파일안보이게
        myProps.refresh_data();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  const get_counsel_file_tags = () => {
    if (strFunc.is_empty(info.counsel_file_arr)) {
      return "";
    }
    let counsel_file_tags = info.counsel_file_arr.map((item: any, idx: number) => {
      let thum_img_src = noimg_img;
      if (item["is_image"] == "1") {
        thum_img_src = item["thum_img_url"];
      }
      return (
        <div key={idx} style={{ textAlign: "center" }}>
          <a href={item.img_url} target="_blank" >
            <img src={thum_img_src} style={{ width: "85%" }} />
          </a>
          {is_view_mode == false &&
            <div>
              {item.a_create_date}
              <button className="btn-s btn-red ml-1" onClick={() => {
                goCounselFileDelete(item);
              }}>X</button>
            </div>
          }
        </div>
      );
    });
    return counsel_file_tags;
  };

  return (
    <div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            <col width={"25%"}></col>
            <col width={"5%"}></col>
            <col width={"25%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th colSpan={2}>직전 시험 성적</th>
              <td colSpan={4} style={{ padding: 0, border: 0 }}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_exam_name
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_exam_name}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_exam_name", value: e.target.value }); }}
                            placeholder="시험명" />
                        }
                      </td>
                      <th>국어</th>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_language_exam_score
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_language_exam_score}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_language_exam_score", value: e.target.value }); }}
                            placeholder="" />
                        }
                      </td>
                      <th>영어</th>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_en_exam_score
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_en_exam_score}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_en_exam_score", value: e.target.value }); }}
                            placeholder="" />
                        }
                      </td>
                      <th>수학</th>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_math_exam_score
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_math_exam_score}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_math_exam_score", value: e.target.value }); }}
                            placeholder="" />
                        }
                      </td>
                      <th>사회</th>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_society_exam_score
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_society_exam_score}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_society_exam_score", value: e.target.value }); }}
                            placeholder="" />
                        }
                      </td>
                      <th>과학</th>
                      <td>
                        {is_view_mode ?
                          add_memo_info.a_pre_science_exam_score
                          :
                          <input type="text" className="row-input" value={add_memo_info.a_pre_science_exam_score}
                            onChange={(e: any) => { handleInputChange2({ name: "a_pre_science_exam_score", value: e.target.value }); }}
                            placeholder="" />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>이전학습 이력</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_pre_edu_history}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_pre_edu_history"}
                    value={add_memo_info.a_pre_edu_history}
                    class_name={"row-input"}
                    height={32}
                    style={{ "width": "100%" }}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_pre_edu_history",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th colSpan={2}>난제 상황</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_matter_condition}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_matter_condition"}
                    value={add_memo_info.a_matter_condition}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_matter_condition",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th colSpan={2}>상담 내용</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_counsel_content}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_counsel_content"}
                    value={add_memo_info.a_counsel_content}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_counsel_content",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th rowSpan={2}>
                상담  시<br />
                주 호소 내용
              </th>
              <th>학생</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_appeal_from_student}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_appeal_from_student"}
                    value={add_memo_info.a_appeal_from_student}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_appeal_from_student",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>학부모</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_appeal_from_parent}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_appeal_from_parent"}
                    value={add_memo_info.a_appeal_from_parent}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_appeal_from_parent",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CounselInfoArea;