import { useState } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import ListArea from "./list_area";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteArea from "./write";

function CounselListArea(props: any) {
  let myProps = {
    is_view_mode: true,
    Style: {},
    counsel_info_arr: [],
    set_counsel_info_arr: (inData: any) => { },
    list: (inData: any) => { },
    stu_info: {},
    select_date_str: "",
    get_counsel_list: (inData: any) => { },
    counselListOpt:{},
    counselCountInfo: { "tot": 0 },
    setCounselCountInfo: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let counsel_info_arr = myProps.counsel_info_arr;
  const [is_view_write, set_is_view_write] = useState(false);
  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let select_date_time = now_date_time;
  let select_date = DateFunc.get_date_format(new Date(), "Y-m-d");
  if (!strFunc.is_empty(myProps.select_date_str)) {
    select_date_time = new Date(myProps.select_date_str).getTime();
    select_date = myProps.select_date_str;
  }

  return (
    <div>
      <div>
        상담({counsel_info_arr.length})
        <button className="btn-m2 btn-sky ml-1" onClick={() => { set_is_view_write(!is_view_write) }} >신규</button>
      </div>
      <div className="mt-1" style={{ display: "inline-block", width: 90, height: 20 }}>

      </div>
      <div>
        {is_view_write &&
          <WriteArea
            stu_info={myProps.stu_info}
            select_date={select_date}
            Style={Style}
            list={(inData: any) => { myProps.list(inData); set_is_view_write(false); }}
            set_is_view_write={set_is_view_write}
          ></WriteArea>
        }
      </div>
      <ListArea
        info_arr={counsel_info_arr}
        set_info_arr={(inData: any) => {
          myProps.set_counsel_info_arr(inData);
        }}
        Style={Style}
        list={myProps.list}
        is_view_mode={myProps.is_view_mode}
        now_date_time={now_date_time}
        select_date_time={select_date_time}
        stu_info={myProps.stu_info}
      ></ListArea>
      <Paging now_page={myProps.counselListOpt.now_page}
        num_per_page={myProps.counselListOpt.num_per_page}
        total_rec={myProps.counselCountInfo.tot}
        onChangePage={(now_page: number) => { myProps.get_counsel_list({ now_page: now_page }); }}
        is_view_num_per_page={true}
        onChangeNumPerPage={(num_per_page: number) => { myProps.get_counsel_list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
};
export default CounselListArea;