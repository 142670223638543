import strFunc from "@/lib/lyg/string";
import WritePage from "@/pages/comp/basic/student/list/area/write";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useRef, useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function ViewStuLayerPopup(props: any) {
  let myProps = {
    write_page_data: {},
    callback: (inData: any) => { },
    closePopup: () => { },
    ...props
  };

  let default_write_page_data = {
    is_show: false,
    is_update: false,
    is_view_mode: true,
    set_is_view_mode: (inData: any) => { },
    is_view_basic: false,
    info_arr: [],
    select_idx: 0,
    default_write_info: {},
    tab_id: "monthly_prescribe",
    is_first_tab_scroll: false,
    counsel_s_select_date: "",
  };
  let write_page_data = {
    ...default_write_page_data,
    ...myProps.write_page_data
  };
  const [info_arr, set_info_arr] = useState(write_page_data.info_arr);
  const [is_view_mode, set_is_view_mode] = useState(write_page_data.is_view_mode);
  const WritePageRef = useRef<any>(null);

  useEffect(() => {
    get_stu_info_arr_by_ajax();
  }, []);

  const get_stu_info_arr_by_ajax = () => {
    let stu_seq_arr: any = [];
    for (let i = 0; i < write_page_data.info_arr.length; i++) {
      let row_info = write_page_data.info_arr[i];
      if (!strFunc.is_empty(row_info.a_seq)) {
        stu_seq_arr.push(row_info.a_seq);
      }
    }
    if (stu_seq_arr.length == 0) {
      return false;
    }
    let tmp_list_opt = {
      "s_pri_arr": stu_seq_arr,
      "s_addon_add_memo": "1",
      s_addon_lecture_time: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',
      tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let tmp_user_info_arr = [];
          for (let i = 0; i < stu_seq_arr.length; i++) {
            for (let j = 0; j < response.data["data"]["info_arr"].length; j++) {
              if (stu_seq_arr[i] == response.data["data"]["info_arr"][j]["a_seq"]) {
                tmp_user_info_arr.push(response.data["data"]["info_arr"][j]);
              }
            }
          }
          set_info_arr(tmp_user_info_arr);
        } else {

        }
      });
  };

  const closePopup = () => {
    if (WritePageRef.current) {
      let is_exist_modify = WritePageRef.current.get_is_exist_modify();
      if (is_exist_modify) {
        if (!window.confirm("작성중인 내용이 있습니다. 닫으시겠습니까?")) {
          return;
        }
      }
    }
    myProps.closePopup();
  };

  let close_btn_style: any = {
    position: "absolute", right: 15, top: 8, color: "#fff", border: "1px solid #fff",
    height: 40, width: 40, fontSize: "18px"
  };

  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let stu_name = "";
  let stu_school_name = "";
  let stu_grade_str = "";
  let select_stu_info: any = {};
  let stu_join_date = "";
  let stu_t_sort = "";
  let lecture_name_str = "";
  if (info_arr && info_arr[write_page_data.select_idx]) {
    select_stu_info = info_arr[write_page_data.select_idx];
    stu_name = select_stu_info["a_user_name"];
    stu_school_name = select_stu_info["a_school_name"];
    stu_grade_str = select_stu_info["a_stu_grade"];
    stu_join_date = select_stu_info["a_join_date"];
    if (strFunc.is_empty(stu_join_date)) {
      stu_join_date = "";
    }
    if (select_stu_info.add_memo_info) {
      stu_t_sort = select_stu_info.add_memo_info.a_stu_total_code;
    }
    if (select_stu_grade_option_arr) {
      for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
        let row_option = select_stu_grade_option_arr[i];
        if (row_option["value"] == stu_grade_str) {
          stu_grade_str = row_option["text"];
        }
      }
    }
    if (select_stu_info.lecture_arr && select_stu_info.lecture_arr.length > 0) {
      for (let i = 0; i < select_stu_info.lecture_arr.length; i++) {
        if (i > 0) {
          lecture_name_str += "/ ";
        }
        lecture_name_str += strFunc.cut_str(select_stu_info.lecture_arr[i].a_name, 4, "..");
      }
    }
  }

  return (
    <div style={{ paddingTop: 45 }}>
      <div style={{ position: "fixed", width: "100%", top: 0, left: 0, zIndex: 99 }}>
        <div style={{
          position: "relative", width: "100%",
          textAlign: "center", background: "#2d8372", borderBottom: "1px solid #ddd", height: 57,
          lineHeight: "26px", color: "#fff"
        }}>
          <div>
            {stu_name}
            {stu_t_sort &&
              <span className="ml-1" style={{ color: "#cff" }}>
                {stu_t_sort}
              </span>
            }
            <span className="ml-1">
              ({strFunc.cut_str(stu_school_name, 2, "")} {stu_grade_str})
            </span>
            {stu_join_date &&
              <span className="ml-1" style={{ color: "#ccc" }}>
                입학일: {stu_join_date}
              </span>
            }
            {lecture_name_str &&
              <span className="ml-2" style={{ color: "#ccc" }}>
                {lecture_name_str}
              </span>
            }
          </div>
          <div>
            {is_view_mode == false &&
              <button className="btn btn-black px-3"
                onClick={() => {
                  WritePageRef.current.go_write_user_data();
                }}>저장</button>
            }
            {is_view_mode &&
              <button className="btn btn-dark ml-1"
                onClick={() => {
                  set_is_view_mode(!is_view_mode);
                  if (WritePageRef.current) {
                    WritePageRef.current.set_is_view_mode(!is_view_mode);
                  }
                }}>
                {is_view_mode ? "수정" : "보기"}모드
              </button>
            }
          </div>
          <button className="btn btn-line-gray" onClick={() => { closePopup(); }}
            style={close_btn_style}>X</button>
        </div>
      </div>
      <WritePage
        ref={WritePageRef}
        info_arr={write_page_data.info_arr}
        select_idx={write_page_data.select_idx}
        close_write={() => { closePopup(); }}
        close_btn_name={"닫기"}
        is_view_close_btn={false}
        is_view_write_btn={false}
        list={() => { myProps.callback(); }}
        refresh_data={() => {
          if (opener && opener.refresh_list) {
            myProps.callback();
          }
        }}
        is_update={write_page_data.is_update}
        is_view_mode={write_page_data.is_view_mode}
        set_is_view_mode={set_is_view_mode}
        is_view_basic={write_page_data.is_view_basic}
        default_write_info={write_page_data.default_write_info}
        tab_id={write_page_data.tab_id}
        is_first_tab_scroll={write_page_data.is_first_tab_scroll}
        counsel_s_select_date={write_page_data.counsel_s_select_date}
      ></WritePage>
    </div>
  );
};
export default ViewStuLayerPopup;