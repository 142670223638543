import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search";
import SubjectGroupArea from "@/pages/comp/edu/month_prescribe/common/subject_group";

const MonthPrescribeTabArea = forwardRef((props: any, ref) => {
  let myProps = {
    stu_info: {},
    is_view_mode: true,
    set_is_view_mode: (inData: any) => { },
    is_exist_modify:false,
    set_is_exist_modify:(inData:boolean)=>{},
    s_select_date:"",
    refresh_data:(inData:any)=>{},
    ...props
  };

  let init_now_date_json=DateFunc.get_date_json(new Date());
  let init_s_select_date=DateFunc.get_date_format(new Date(), "Y-m-d");
  if(!strFunc.is_empty(myProps.s_select_date)){
    init_s_select_date=myProps.s_select_date;
  }
  
  if((init_now_date_json.d+1)<init_now_date_json.day){
    let init_minus_select_d=init_now_date_json.day-(init_now_date_json.d+1);
    init_s_select_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'day',-init_minus_select_d), "Y-m-d");
  }
  
  let init_list_opt = {
    s_start_date: DateFunc.get_date_format(new Date(init_s_select_date), "Y-m-01"),
    s_end_date: DateFunc.get_date_format(new Date(init_s_select_date), "Y-m-t"),
  };
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "max_limit_num": "1000",
    "order_id": "a_reserve_date DESC, a_update_date DESC",
    "s_date_type": "a_reserve_date",
    "s_start_date": init_list_opt.s_start_date,
    "s_end_date": init_list_opt.s_end_date,
    "s_stu_seq": myProps.stu_info["a_seq"],
    "s_writer_seq": "",
    "s_is_success": "",
    "s_subject": "",
    "s_addon_counsel": "1",
    "s_addon_file":"1",
    "s_addon_daily_report_last":"1",
    ...myProps.list_opt
  });
  
  const [stu_info, set_stu_info] = useState(myProps.stu_info);
  const [info_arr, set_info_arr] = useState([]);
  const [stu_subject_arr, set_stu_subject_arr] = useState<any>([]);
  const [refresh, set_refresh] = useState(false);
  const [comment_list,set_comment_list]=useState([]);
  const last_daily_report_arr=stu_info.last_daily_report_arr;
  const SubjectGroupAreaRef=useRef<any>(null);
  let is_view_mode = myProps.is_view_mode;
  let last_mprescribe_arr=[];
  if(myProps.stu_info.last_mprescribe_arr){
    last_mprescribe_arr=myProps.stu_info.last_mprescribe_arr;
  }

  useEffect(() => {
    setInitData({
      stu_info: myProps.stu_info
    });
  }, [myProps.stu_info]);

  const setInitData = (inData: any) => {
    let opt_obj = {
      stu_info: {},
      ...inData
    };
    set_stu_info(opt_obj.stu_info);
    list({
      s_stu_seq: opt_obj.stu_info["a_seq"],
    });
    get_stu_subject_arr_by_ajax({
      stu_info: opt_obj.stu_info,
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,go_write_info_arr_by_ajax,
    go_auto_save
  }));

  const go_auto_save=()=>{
    if(SubjectGroupAreaRef.current){
      SubjectGroupAreaRef.current.go_auto_save();
    }
  };

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    let tmp_list_form_json = { ...list_form_json };
    if (strFunc.is_empty(tmp_list_form_json["s_stu_seq"])) {
      return false;
    }

    //검색날짜 앞뒤로 7일씩 더하기.
    tmp_list_form_json["s_start_date"] = DateFunc.get_date_format(
      DateFunc.get_change_date(new Date(tmp_list_form_json["s_start_date"]), 'day', -7)
      , "Y-m-d");
    tmp_list_form_json["s_end_date"] = DateFunc.get_date_format(
      DateFunc.get_change_date(new Date(tmp_list_form_json["s_end_date"]), 'day', +7)
      , "Y-m-d");

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.set_is_exist_modify(false);
          set_info_arr(response.data["data"]["info_arr"]);
          get_comment_list_by_ajax({
            info_arr:response.data["data"]["info_arr"],
            s_start_date:tmp_list_form_json["s_start_date"],
          });
        } else {

        }
      });
  };
  
  const get_comment_list_by_ajax = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      s_start_date:"",
      s_end_date:DateFunc.get_date_format(new Date(),"Y-12-31"),
      ...inData
    };
    if (opt_obj["info_arr"].length==0) {
      set_comment_list([]);
      return false;
    }
    let row_pri_val_arr=[];
    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let info=opt_obj["info_arr"][i];
      if(!strFunc.is_empty(info["a_seq"])){
        row_pri_val_arr.push(info["a_ymd"]+","+info["a_seq"]);
      }
    }
    if(row_pri_val_arr.length==0){
      set_comment_list([]);
      return false;
    }
    let formJsonData = {
      "s_par_id": "month_prescribe",
      "s_par_seq": row_pri_val_arr,
      "order_id": "a_create_date",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_stu_subject_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      "stu_info": stu_info,
      ...inData
    };

    let from_json_data = {
      "s_stu_seq": opt_obj["stu_info"]["a_seq"],
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(from_json_data["s_stu_seq"])) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_stu_subject/list', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_subject_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            write_stu_subject_default_by_ajax({stu_info:opt_obj["stu_info"]});
          }
        } else {

        }
      });
  };

  const write_stu_subject_default_by_ajax = (inData:any) => {
    let opt_obj={
      "stu_info": stu_info,
      ...inData
    };
    //없다면 기본 과목 넣어주기.
    if (strFunc.is_empty(opt_obj.stu_info["a_seq"])) {
      return false;
    }
    let default_write_stu_subject_arr = [
      {
        a_stu_seq: opt_obj.stu_info["a_seq"],
        a_name: "수학",
        a_stu_name: opt_obj.stu_info["a_user_name"],
        a_order_num: 1,
      },
      {
        a_stu_seq: opt_obj.stu_info["a_seq"],
        a_name: "영어",
        a_stu_name: opt_obj.stu_info["a_user_name"],
        a_order_num: 2,
      }
    ];
    let from_json_data = {
      "data_arr": default_write_stu_subject_arr,
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_stu_subject/write', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_subject_arr(default_write_stu_subject_arr);
          set_refresh(!refresh);
        } else {

        }
      });
  };

  const go_write_info_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      write_data_arr: [],
      ...inData
    };
    let write_data_arr:any = [];
    if (info_arr.length == 0) {
      for(let i=0;i<info_arr.length;i++){
        let info=info_arr[i];
        if(info["a_lack"]!=""||info["a_grow"]!=""){
          write_data_arr.push(info);
        }
      }
    }
    if (write_data_arr.length == 0) {
      return false;
    }
    let write_form_json: any = {
      "data_arr": write_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          // set_is_view_mode(true);
          //myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div className="mt-2">
      <SearchArea
        listOpt={listOpt}
        list={list}
      ></SearchArea>
      <div>
        <SubjectGroupArea
          ref={SubjectGroupAreaRef}
          stu_info={stu_info}
          last_daily_report_arr={last_daily_report_arr}
          last_mprescribe_arr={last_mprescribe_arr}
          info_arr={info_arr}
          set_info_arr={(inData:any)=>{
            set_info_arr(inData);
            myProps.set_is_exist_modify(true);
          }}
          stu_subject_arr={stu_subject_arr}
          set_stu_subject_arr={set_stu_subject_arr}
          list={(inData: any) => {
            list(inData);
            get_stu_subject_arr_by_ajax({});
            myProps.refresh_data({});
          }}
          set_refresh={() => { set_refresh(!refresh); }}
          select_date={listOpt.s_start_date}
          is_view_mode={is_view_mode}
          set_is_view_mode={myProps.set_is_view_mode}
          comment_list={comment_list}
          refresh_comment={(inData:any)=>{}}
        ></SubjectGroupArea>
      </div>
      <div>
        <div className="mt-1">※월요일 기준으로 1~5주차 결정.</div>
      </div>
    </div>
  );
});
export default MonthPrescribeTabArea;