import { useState, useRef, useEffect} from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import getXcolumnJson from "./xcolumn/list";

function WriteArea(props:any){
   let myProps={
    stu_info:{},
    Style:{},
    list:(inData:any)=>{},
    isUpdate:false,
    set_is_view_write:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  const xColumnArr=getXcolumnJson();
  let user=useSelector((state:any) => state.user);
  const fileWriteOneRef = useRef<any>(null);
  const FileListWriteFrameRef=useRef<any>(null);
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }

  const init_data={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_stu_seq":myProps.stu_info["a_seq"],
    "a_stu_name":myProps.stu_info["a_user_name"],
    "a_sort1":"",
    "a_sort2":"",

    "a_counsel_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_reserve_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_writer_seq":user.user_seq,
    "a_writer":user.user_name,
    "a_counseler_seq":user.user_seq,
    "a_counseler":user.user_name,
    "a_title":"상담",
    "a_content":"",
    "a_is_success":"1",
    "a_state":"complete",
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    //a_state
    
    let change_row_data:any={...rowData};
    if(name=="a_state"){
      if(value=="complete"){
        change_row_data["a_is_success"]="1";
        if(strFunc.is_empty(change_row_data["a_counsel_date"])){
          change_row_data["a_counsel_date"]=DateFunc.get_date_format(new Date(),"Y-m-d");
        }
      }else{
        change_row_data["a_is_success"]="";
      }
    }

    change_row_data[name]=value;
    setRowData(change_row_data);
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=(e:any) => {
    if(rowData.a_stu_seq==""){
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};
    if(!isUpdate){
      wRowData["a_ymd"]=strFunc.str_replace("-","",wRowData["a_reserve_date"]);
    }
    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("저장 하였습니다.");
        if(fileWriteOneRef.current){
          let childFileInput=fileWriteOneRef.current.getFileInput();
          if(childFileInput.current.value==""){
            props.list({});
          }else{
            let priVal=xColumnArr.func.getPriValByDataRow({
              xColumnArr:xColumnArr,
              rowData:response.data["data"][0]
            });
            fileWriteOneRef.current.goWrite({
              "a_par_seq":priVal
            });
          }
        }else{
          props.list({});
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  };
  
  return (
  <div className={Style.write_wrap}>
    <div>상담 작성</div>
    <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td colSpan={3}>
                {rowData.a_stu_name}
              </td>
            </tr>
            <tr>
              <th>상담예정</th>
              <td colSpan={3}>
                <DatePicker 
                    selected={rowData.a_reserve_date!=""?new Date(rowData.a_reserve_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_reserve_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
              </td>
            </tr>
            <tr>
              <th>상담일</th>
              <td colSpan={3}>
                <DatePicker 
                    selected={rowData.a_counsel_date!=""?new Date(rowData.a_counsel_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_counsel_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td colSpan={3}>
                <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange} >
                  <option value="ready">준비</option>
                  <option value="complete">완료</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>상담내용</th>
              <td colSpan={3}>
                <textarea className="row-input h-20" name="a_content" value={rowData.a_content} 
                  onChange={handleInputChange} placeholder="내용"
                  style={{height:100}} ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        rowData.a_seq=="" && 
        <WriteInputFrame
        ref={fileWriteOneRef}
        init_data={{
          a_par_id:xColumnArr.table,
          a_par_seq:rowData.a_ymd+","+rowData.a_seq,
        }}
        callback={()=>{
          props.list({});
        }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.set_is_view_write(false);}}>닫기</button>
      </div>
      {
        rowData.a_seq!="" && 
        <FileListWriteFrame
          ref={FileListWriteFrameRef}
          listOpt={
            {
              s_par_id:xColumnArr.table,
              s_par_seq:xColumnArr.func.getPriValByDataRow({
                xColumnArr:xColumnArr,
                rowData:rowData
              }),
              s_start_date:rowData.a_reserve_date
            }
          }
          callback={(inData:any)=>{myProps.list({});}}
        ></FileListWriteFrame>
      }
  </div>
  );
};
export default WriteArea;