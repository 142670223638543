import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';

function FindMultyStaffPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    listOpt: {},
    row_num: 0,
    key: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["teacher", "master", "staff"],
    s_except_user_seq: "",
    s_is_login: "1",
    s_user_name_like:"",
    s_addon_comp_link: "1",
    ...myProps.listOpt
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_listOpt={
      ...listOpt,
      ...inOptObj
    };
    tmp_listOpt["sc"] = listAggridRef.current.getListSc();
    setListOpt(tmp_listOpt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goCallBackData = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_stu_num") {
      render_str = strFunc.getNumber2(render_str);
    } else if (key == "row_view_is_app") {
      if (params.data.comp_link_info) {
        render_str = (<div className="text-gray-500">가입(로그아웃)</div>);
        if (params.data.comp_link_info.main_u_info && params.data.comp_link_info.main_u_info.a_push_token) {
          render_str = (<div className="text-green-500">가입</div>);
        }
      }
    }else if (key == "row_view_select") {
      render_str = (<button className="btn btn-dark" onClick={() => { 
        setTimeout(() => {
          goCallBackData();
        }, 100);
       }}>선택</button>);
    }

    return render_str;
  };
  const getRowHeight = useCallback((params: any) => {
    return 45;
  }, []);

  return (
    <div>
      <div className="btn-box-left relative h-8">
        <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
        <span className="px-2">|</span>
        <SearchOrderRadio
          valueTextArr={[
            { "text": "등록순", "value": "a_create_date DESC" },
            { "text": "이름순", "value": "a_user_name" },
          ]}
          value={listOpt.order_id}
          handleInputChange={(e: any) => {
            list({
              "now_page": 1,
              "order_id": e.target.value
            });
          }}
        ></SearchOrderRadio>
        <span className="px-2">|</span>
        <input type="text" className="input w-40" placeholder="이름" value={listOpt.s_user_name_like} onChange={(e) => { list({ "s_user_name_like": e.target.value }); }} />
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: true,
          floatingFilter: false,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          getRowHeight: getRowHeight
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
}

export default FindMultyStaffPopup;