import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";
import WriteArea from "./area/write";

function ClassUserManageArea(props: any) {
  let myProps = {
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const WriteAreaRef=useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      "s_is_use":"1",
      "s_is_main":"",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "select_seq":"",
      s_addon_class_user: "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);

  useEffect(() => {
    
  }, []);

  const list = (inData: any) => {
    if(listAggridRef.current){
      inData["sc"] = listAggridRef.current.getListSc();
    }
    let tmpPageData = {
      ...pageData,
    };
    tmpPageData.listOpt={
      ...inData
    };
    setPageData(tmpPageData);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/list', tmpPageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setTimeout(()=>{
            let list_nodes=listAggridRef.current.getNodeRows();
            for(let i=0;i<list_nodes.length;i++){
              let node=list_nodes[i];
              if(node.data.a_seq==tmpPageData.listOpt.select_seq){
                node.setSelected(true);
              }else{
                node.setSelected(false);
              }
            }
          },100);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/delete', form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const moveOrderNum=(row_data:any,up_down:string)=>{
    let tmp_section_arr:any=[...listAggridRef.current.getRows()];
    let row_num=0;
    for(let i=0;i<tmp_section_arr.length;i++){
      if(tmp_section_arr[i]["a_seq"]==row_data["a_seq"]){
        row_num=i;
      }
    }
    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;
    save_list_order_num_by_arr(tmp_section_arr);
    setTimeout(()=>{
      setInfoArr(tmp_section_arr);
      listAggridRef.current.setInfoArr(tmp_section_arr);
    },100);
  };

  const save_list_order_num_by_arr=(tmp_section_arr:[])=>{
    let w_order_info_arr=[];
    for(let i=0;i<tmp_section_arr.length;i++){
      w_order_info_arr.push({
        "a_seq":tmp_section_arr[i]["a_seq"],
        "a_order_num":i,
      });
    }
    let write_form_data={
      "is_update":"1",
      "data_arr":w_order_info_arr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/en_class/write',write_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_stu_cnt") {
      if (params.data.student_arr) {
        render_str = params.data.student_arr.length + " 명";
      }
    } else if (key == "row_view_coolen") {
      if(!strFunc.is_empty(params.data["a_coolen_class_seq"])){
        render_str = (<span style={{color:"blue"}}>연동</span>);
      }
    } else if (key == "row_view_coolenb") {
      if(!strFunc.is_empty(params.data["a_coolenb_class_seq"])){
        render_str = (<span style={{color:"blue"}}>연동</span>);
      }
    }else if(key=="row_view_main"){
      if(params.data["a_is_main"]=="1"){
        render_str="메인";
      }else{

      }
    }else if(key=="row_view_order"){
      render_str=(
        <div className="text-left">
          <button className="btn-s btn-dark" onClick={()=>{moveOrderNum(params.data,"up");}} >▲</button>
          <button className="btn-s btn-dark ml-1" onClick={()=>{moveOrderNum(params.data,"down");}} >▼</button>
        </div>
      );
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if(selected_row.length==1){
      if(WriteAreaRef.current){
        WriteAreaRef.current.set_init_data({
          row_data:selected_row[0],
          is_update:true,
        });
      }
    }else if(selected_row.length==0){
      WriteAreaRef.current.set_init_data({
        is_update:false,
      });
    }
  };
  const setWriteState=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length>0){
      listAggridRef.current.setSelectFalseAll();
    }
  };

  return (
    <div>
      <div className="flex flex-row gap-x-2">
        <div style={{ minWidth: 460 }} >
          <SearchArea
            list_opt={pageData.listOpt}
            list={list}
            setWriteState={setWriteState}
            goDelete={goDelete}
          ></SearchArea>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{ ...xColumnArr }}
            list={list}
            gridOpt={{
              fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num: false,
              is_add_checkbox: true,
              floatingFilter: false,
              onGridReady: () => {
                list({});
              },
              onCellClicked: cellClickedListener,
              cellRenderer: cellRenderer,
              onSelectionChanged: onSelectionChanged
            }}
          ></ListAggrid>
        </div>
        <div style={{ minWidth: 410 }} className="grow">
          <WriteArea
            ref={WriteAreaRef}
            list={list}
          ></WriteArea>
        </div>
      </div>
    </div>
  );
};
export default ClassUserManageArea;