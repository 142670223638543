import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";

function ViewPopupContent(props:any) {
  let myProps={
    is_view_write_btn:true,
    ...props
  };
  //props.rowData.a_user_name;
  const [info,set_info]=useState(props.rowData);
  useEffect(()=>{
    //get_info_by_ajax();
  },[]);
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const get_info_by_ajax=()=>{
    if(strFunc.is_empty(info["a_seq"])){
      return false;
    }
    let pri_val=info["a_ymd"]+","+info["a_seq"];
    let tmp_list_opt={
      "s_pri_arr":[pri_val],
      "s_addon_user": "1",
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };
  let row_data={...info};
  let stu_grade_str = "";
  if(info.stu_info){
    row_data["a_stu_name"]=info.stu_info["a_user_name"];
    stu_grade_str = row_data.stu_info.a_stu_grade;
    for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
      if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
        stu_grade_str = select_stu_grade_option_arr[i]["text"];
      }
    }
  }

  let week_i_str:any=parseInt(strFunc.uncomma(row_data.week_i));
  let month_num=parseInt(strFunc.uncomma(row_data.month_num));
  week_i_str++;
  if(week_i_str==0){
    week_i_str="전달 마지막";
  }else{
    week_i_str=month_num+"월 "+week_i_str;
  }
  let init_content_title_str = MonthPrescribeViewFunc.get_view_text_str({info:row_data});
  const [content_title_str,set_content_title_str]=useState(init_content_title_str);
  const [copy_msg,set_copy_msg]=useState("");

  const copy_str_to_clipboard=(str:string)=>{
    navigator.clipboard.writeText(str);
    set_copy_msg("복사됨.");
    setTimeout(()=>{
      set_copy_msg("");
    },2000);
  }
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>
                {row_data.a_stu_name}
                {row_data.stu_info&&
                <span className="ml-1" style={{ color: "#737373" }} >
                  ({strFunc.cut_str(row_data.stu_info["a_school_name"], 3, "")} {stu_grade_str})
                </span>
                }
              </td>
              <th>날짜</th>
              <td>
                {row_data.a_reserve_date}
              </td>
            </tr>
            <tr>
              <th colSpan={4}>내용</th>
            </tr>
            <tr>
              <td colSpan={4}>
                <TextAreaComponent
                  name={"content_title_str"}
                  value={content_title_str}
                  onChange={(e:any)=>{
                    set_content_title_str(e.target.value);
                  }}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{textAlign:"center"}}>
                <button className="btn btn-sky" onClick={()=>{copy_str_to_clipboard(content_title_str);}}>복사</button>
                <span className="ml-1" style={{color:"blue"}}>{copy_msg}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {/* {myProps.is_view_write_btn&&
        <button className="btn btn-line-gray" onClick={()=>{
            props.closePopup();
            props.openOnePopup("write",true);
          }}>수정</button>
        } */}
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
