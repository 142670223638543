import strFunc from "@/lib/lyg/string";
import noimg_ico from "@/img/ico/noimg.png";
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import popupFunc from "@/lib/lyg/popup";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import CounselBox from "./counsel_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ListArea(props: any) {
  let myProps = {
    stu_info: {},
    info_arr: [],
    set_info_arr: (inData: any) => { },
    Style: {},
    now_date_time: "",
    select_date_time: "",
    list: (inData: any) => { },
    is_view_mode: true,
    ...props
  };
  let info_arr = myProps.info_arr;
  let Style = myProps.Style;
  let now_date_time = myProps.now_date_time;
  let select_date_time = myProps.select_date_time;
  let is_view_mode = myProps.is_view_mode;

  const downloadFormRef = useRef<HTMLFormElement>(null);
  const user = useSelector((state: any) => state.user);

  let select_arr_a_kind: any = XcolumnFunc.getSortByTableKey({ 'table': 'counsel_todo', 'key': 'a_kind' });
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  useEffect(() => {
    
  }, []);

  const on_change_row_input = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      key: "",
      value: "",
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    if (is_view_mode) {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);
    let key = opt_obj["key"];
    let value = opt_obj["value"];

    let change_counsel_todo_arr: any = [...info_arr];
    change_counsel_todo_arr[row_num][key] = value;

    myProps.set_info_arr(change_counsel_todo_arr);
  };
  const on_change_row_date_picker = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      key: "",
      value: "",
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    if (is_view_mode) {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let date_str = DateFunc.get_date_format(value, "Y-m-d");

    let change_counsel_todo_arr: any = [...info_arr];
    change_counsel_todo_arr[row_num][key] = date_str;

    myProps.set_info_arr(change_counsel_todo_arr);
  };

  const update_content_by_ajax = (select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let change_data_arr = [];
    for (let i = 0; i < select_arr.length; i++) {
      let info = select_arr[i];
      change_data_arr.push({
        "a_ymd": info["a_ymd"],
        "a_seq": info["a_seq"],
        "a_kind": info["a_kind"],
        "a_title": info["a_title"],
        "a_content": info["a_content"],
        "a_feedback": info["a_feedback"],
        "a_eval_score": info["a_eval_score"],
        "a_reference": info["a_reference"],
        "a_counsel_date": info["a_counsel_date"],
        "a_reserve_date": info["a_reserve_date"],
      });
    }
    if (change_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": change_data_arr,
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const goWriteFileUpload = (row_data: any) => {
    let is_file_exist = false;
    if (row_data.file_obj && row_data.file_obj.value) {
      is_file_exist = true;
    }
    if (is_file_exist == false) {
      alert("파일 선택이 없습니다.");
      myProps.list({});
    }
    if(!confirm("파일을 저장 하시겠습니까?")){
      return false;
    }
    let row_pri_val = row_data.a_ymd + "," + row_data.a_seq;
    let file_row_data = {
      "a_ymd": row_data.a_ymd,
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "counsel_todo",
      "a_par_seq": row_pri_val,
      "a_sort1": "image",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };

    var form = row_data.file_obj.parentElement;
    var form_json_data = new FormData(form);

    form_json_data.append("data_arr", JSON.stringify([file_row_data]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          row_data.file_obj.value="";
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_coundel_by_ajax = (select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("해당 상담을 삭제 하시겠습니까?")) {
      return false;
    }

    let form_json_data = {
      "data_arr": select_arr,
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const change_state_by_ajax = (change_state: string, select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let confirm_msg = "완료 처리 하시겠습니까?";
    if (change_state == "complete") {
      confirm_msg = "완료 처리 하시겠습니까?";
    } else {
      confirm_msg = "준비 중으로 바꾸시겠습니까?";
    }
    if (!confirm(confirm_msg)) {
      return false;
    }

    let change_data_arr = [];
    for (let i = 0; i < select_arr.length; i++) {
      let info = select_arr[i];
      let a_is_success = "";
      let a_counsel_date = info["a_counsel_date"];
      if (change_state == "complete") {
        a_is_success = "1";
        if (info["a_is_success"] != "1") {
          a_counsel_date = DateFunc.get_date_format(new Date(), "Y-m-d");
        }
      }
      let change_data_row = {
        "a_ymd": info["a_ymd"],
        "a_seq": info["a_seq"],
        "a_is_success": a_is_success,
        "a_state": change_state,
        "a_counsel_date": a_counsel_date
      };
      change_data_arr.push(change_data_row);
    }
    if (change_data_arr.length == 0) {
      alert("변경할 데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": change_data_arr,
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("변경 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDownLoad = (file_info: any) => {
    let pri_val = file_info["a_ymd"] + "," + file_info["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = file_info["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };
  const goDeleteFile = (file_info_arr: any) => {
    let selected_row = file_info_arr;
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let del_row_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let file_info = selected_row[i];
      del_row_arr.push({
        "a_ymd": file_info["a_ymd"],
        "a_seq": file_info["a_seq"],
      });
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: del_row_arr,
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제 되었습니다.");
        myProps.list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const get_file_tags_by_file_arr = (file_arr: []) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let icon_img = noimg_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} className={Style.row_content_file_row}>
          {file_info.is_image == "1" &&
            <a style={{ cursor: "pointer" }} onClick={() => { popupFunc.openPopup({ url: file_info.img_url }); }} >
              <img src={icon_img} className={Style.row_content_file_row_thum} />
            </a>
          }
          <p>
            {file_info.a_oriname}
            <button className="btn-s btn-gray ml-1" onClick={() => { goDownLoad(file_info); }}>다운</button>
            <button className="btn-s btn-red ml-1" onClick={() => { goDeleteFile([file_info]); }} >X</button>
          </p>
        </div>
      );
    });

    return file_tags;
  };

  const get_counsel_box_tags = () => {
    let list_length=myProps.info_arr.length;
    let counsel_box_tags = myProps.info_arr.map((counsel_info: any, idx: number) => {

      let input_file_names = [];
      if (counsel_info.file_obj) {
        for (let i = 0; i < counsel_info.file_obj.files.length; i++) {
          if (counsel_info.file_obj.files[i].name) {
            input_file_names.push(counsel_info.file_obj.files[i].name);
          }
        }
      }

      let state_text = "준비";
      let state_back_color = "#eab358";
      if (select_date_time < now_date_time) {
        state_text = "미완료";
        state_back_color = "#db6c58";
      }
      if (counsel_info.a_state == "complete") {
        state_text = "완료";
        state_back_color = "#A2B854";
      }

      let file_tags: any = "";
      if (counsel_info.file_arr) {
        file_tags = get_file_tags_by_file_arr(counsel_info.file_arr);
      }

      return (
        <CounselBox
          key={idx}
          row_num={idx}
          list_length={list_length}
          counsel_info={counsel_info}
          info_arr={info_arr}
          set_info_arr={myProps.set_info_arr}
          select_date_time={select_date_time}
          now_date_time={now_date_time}
          Style={Style}
          goDownLoad={goDownLoad}
          goDeleteFile={goDeleteFile}
          goWriteFileUpload={goWriteFileUpload}
          is_view_mode={is_view_mode}
          update_content_by_ajax={update_content_by_ajax}
          delete_coundel_by_ajax={delete_coundel_by_ajax}
          on_change_row_date_picker={on_change_row_date_picker}
          change_state_by_ajax={change_state_by_ajax}
          on_change_row_input={on_change_row_input}
          list={myProps.list}
          stu_info={myProps.stu_info}
          select_arr_a_kind={select_arr_a_kind}
          select_arr_eval_score={select_arr_eval_score}
        ></CounselBox>
      );
    });

    if (strFunc.is_empty(counsel_box_tags)) {
      counsel_box_tags = (
        <div style={{ height: 50, textAlign: "center", lineHeight: "50px", color: "#777" }}>
          내용이 없습니다.
        </div>
      );
    }

    return counsel_box_tags;
  };

  return (
    <div>
      <div className={Style.counsel_list_wrap}>
        {get_counsel_box_tags()}
      </div>
      <form ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
    </div>
  );
};
export default ListArea;