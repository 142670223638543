import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import WritePage from "@/pages/comp/basic/student/list/area/write";
import strFunc from '@/lib/lyg/string';

const StuInfoArea = forwardRef((props: any, ref) => {
  let myProps = {
    close_write_diary_page:(inData:any)=>{},
    ...props
  };

  const WritePageRef = useRef<any>(null);
  const [select_stu_seq, set_select_stu_seq] = useState("");
  const [select_cousel_seq, set_select_cousel_seq] = useState("");
  const [select_date, set_select_date] = useState("");

  const setInitData = (inData: any) => {
    let opt_obj = {
      select_stu_seq: select_stu_seq,
      select_cousel_seq: select_cousel_seq,
      select_date: select_date,
      ...inData
    };
    set_select_stu_seq(opt_obj.select_stu_seq);
    set_select_cousel_seq(opt_obj.select_cousel_seq);
    set_select_date(opt_obj.select_date);
    if(WritePageRef.current){
      WritePageRef.current.setInitData({
        info_arr:[{ a_seq: opt_obj.select_stu_seq }],
        select_idx:0,
        counsel_s_select_date:opt_obj.select_date
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  return (
    <div>
      {!strFunc.is_empty(select_stu_seq) &&
        <WritePage
          ref={WritePageRef}
          info_arr={[{ a_seq: select_stu_seq }]}
          select_idx={0}
          close_write={() => {
            myProps.close_write_diary_page();
           }}
          list={() => { }}
          is_view_mode={true}
          is_update={true}
          is_view_stu_info={false}
          is_view_parent={false}
          is_view_supply={false}
          tab_id={"study_and_counsel"}
        ></WritePage>
      }
    </div>
  );
});
export default StuInfoArea;