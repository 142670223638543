import { Link } from 'react-router-dom';
import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";
import RightSmsList from "./area/sms_list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import SendSMSPopup from "@/pages/comp/basic/sms/popup/send_sms";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';

function ListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["student"],
      s_addon_class_user:"1",
      s_addon_link_user:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [checked_phone_user,set_checked_phone_user]= useState({
    "student":true,
    "parent":false,
  });
  const [mcomp_point,set_mcomp_point] = useState<any>(0);
  const RightSmsListRef=useRef<any>(null);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    receive_arr:[],//[{phone_num:"",user_name:"",user_seq:""}]
    sort:"send_sms",
    title:"팝업",
    width:"700px",
    height:"65%",
  });

  useEffect(() => {
    
    getPointNumByAjax();
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        RightSmsListRef.current.list({});
      }else{
        
      }
    });
  };

  const getPointNumByAjax=()=>{
    if(user.comp_seq){

    }else{
      return false;
    }
    let point_form_json={
      "s_pri_arr":[user.comp_seq],
      "is_addon_point_tot":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/mcomp/list',point_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let corp_info=response.data["data"]["info_arr"][0];
          if(corp_info.point_tot_info){
            set_mcomp_point(corp_info.point_tot_info.tot);
          }
        }
      }else{
        
      }
    });
  };

  const send_sms_popup=()=>{
    let receive_arr:any=[];
    
    let selected_row=listAggridRef.current.getSelectedRows();
    for(let i=0;i<selected_row.length;i++){
      let row_data=selected_row[i];
      if(checked_phone_user.student){
        receive_arr.push({
          "user_seq":row_data.a_seq,
          "phone_num":strFunc.autoHypenPhone(row_data.a_user_phone),
          "user_name":row_data.a_user_name
        });
      }
      if(checked_phone_user.parent){
        if(row_data.link_user_arr&&row_data.link_user_arr[0]&&row_data.link_user_arr[0]["par_info"]){
          let par_info=row_data.link_user_arr[0]["par_info"];
          receive_arr.push({
            "user_seq":par_info.a_seq,
            "phone_num":strFunc.autoHypenPhone(par_info.a_user_phone),
            "user_name":par_info.a_user_name
          });
        }
      }
    }
    setPopupData({
      ...popupData,
      isOpen:true,
      receive_arr:receive_arr,
      sort:"send_sms",
      title:"문자보내기",
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="row_view_par_phone"){
      let par_phone_num="학부모 미등록";
      if(params.data.link_user_arr&&params.data.link_user_arr[0]&&params.data.link_user_arr[0]["par_info"]){
        par_phone_num=params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str=par_phone_num;
    }
 
    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    let receiver_seq_arr:any=[];
    for(let i=0;i<selected_row.length;i++){
      let row_data=selected_row[i];
      receiver_seq_arr.push(row_data.a_seq);
      if(row_data.link_user_arr&&row_data.link_user_arr[0]&&row_data.link_user_arr[0]["par_info"]){
        let par_info=row_data.link_user_arr[0]["par_info"];
        receiver_seq_arr.push(par_info.a_seq);
      }
    }
    RightSmsListRef.current.list({
      "now_page":"1",
      "s_receiver_seq":receiver_seq_arr
    });
  }

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{minWidth:280}}>
            <div>
              <SearchArea 
              listOpt={{...pageData.listOpt}}
              xColumnArr={{...xColumnArr}}
              list={list}
              ></SearchArea>
              <div style={{position:"relative"}}>
                <label>
                  <input type="checkbox" checked={checked_phone_user.student} onChange={(e:any)=>{
                    set_checked_phone_user({
                      ...checked_phone_user,
                      student:e.target.checked
                    });
                  }} /> 학생
                </label>
                <label className="ml-2">
                  <input type="checkbox" checked={checked_phone_user.parent} onChange={(e:any)=>{
                    set_checked_phone_user({
                      ...checked_phone_user,
                      parent:e.target.checked
                    });
                  }} /> 학부모
                </label>
                <button className="btn btn-dark ml-1" onClick={()=>{send_sms_popup();}} >문자보내기</button>

                <span style={{position:"absolute",right:5}}>
                  포인트
                  <span className="ml-2" style={{color:"red"}}>{strFunc.comma(mcomp_point)}</span>
                </span>
              </div>
            </div>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{...xColumnArr}}
              list={list}
              gridOpt={{
                fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num:true,
                is_add_checkbox:true,
                floatingFilter:true,
                onGridReady:()=>{
                  list({});
                },
                onCellClicked:cellClickedListener,
                cellRenderer:cellRenderer,
                onSelectionChanged:onSelectionChanged,
              }}
              ></ListAggrid>
              <Paging now_page={pageData.listOpt.now_page}
                num_per_page={pageData.listOpt.num_per_page}
                total_rec={countInfo.tot} 
                onChangePage={(now_page:number)=>{list({now_page:now_page});}}
                onChangeNumPerPage={(num_per_page:number)=>{list({num_per_page:num_per_page,now_page:1});}}></Paging>
                
          </div>
          <div className="grow" style={{minWidth:450}} >
            <RightSmsList
              ref={RightSmsListRef}
              list={list}
            ></RightSmsList>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} is_win_max_width={true} >
        
        {popupData.sort==="send_sms"&&
        <SendSMSPopup
          receive_arr={popupData.receive_arr}
          callBackRefresh={()=>{getPointNumByAjax();RightSmsListRef.current.list({});}}
          closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
        ></SendSMSPopup>
        }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;